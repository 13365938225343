/* Grid */
#summer .column {
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
#summer .border-right {
  border-right: 1px solid black;
}
#summer .column-display {
  display: flex;
  flex-direction: column;
}
#summer .row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  width: 100%;
}
#summer .item {
  flex-grow: 1;
}
#summer .item-button {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1.5vw;
  text-decoration: none;
}
#summer .item-top {
  border-bottom: 1px solid black;
}
#summer .item-center {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
#summer .item-bottom {
  border-top: 1px solid black;
}
#summer .item-left {
  border-right: 1px solid black;
}
#summer .item-center-row {
  border-right: 1px solid black;
  border-left: 1px solid black;
}
#summer .item-right {
  border-left: 1px solid black;
}
#summer .goal-border {
  border: 1px solid black;
}
#summer .no-left {
  border-left: none;
}
#summer .no-right {
  border-right: none;
}
#summer .no-top {
  border-top: none;
}
#summer .no-bottom {
  border-bottom: none;
}
#summer .container {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
}

#summer .hero {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}
#summer .background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}

#summer .ask-container .answer {
  font-family: 'Roboto-Regular';
  font-size: 1vw;
  line-height: 3vh;
  padding-left: 10px;
  padding-right: 30px;
}

#summer .hero .hero-info {
  align-items: center;
  display: flex;
  bottom: 0;
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  line-height: 3.1vw;
  justify-content: flex-end;
  left: 0;
  position: absolute;
}
#summer .hero .hero-info div {
  padding: 4vw;
  text-transform: uppercase;
}


#summer .info-text {
  display: flex;
  height: 100%;
  padding: 6vw;
  width: 100%;
  align-items: center;
  justify-content: center;
}
#summer .info-text h3 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  margin-bottom: 1.3vw;
}
#summer .info-text p {
  font-size: 1.2vw;
  line-height: 1.8vw;
  margin-bottom: 1vw;
  font-family: 'Roboto-Light';
  font-weight: 600;
}


#summer .info-container {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
  width: 100%;
}

#summer .announcement {
  height: 100%;
  width: 100%;
}


#summer .grid-announcement-image {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-bottom: 1px solid black;
  display: flex;
  flex-grow: 2;
}
#summer .grid-announcement-block {
  border-top: 1px solid black;
  display: flex;
  flex-grow: 1;
}

#summer .font-button {
  font-family: 'Grandis_Light';
  font-weight: 100;
}

#summer .container-announcement {
  flex-direction: column;
  overflow: hidden;
}

#summer .grid-announcement-block {
  border-top: 1px solid black;
}
#summer .announcement-info-container {
  bottom: 0;
  height: 100px;
  left: 0;
  padding: 3vw;
  position: absolute;
  width: 100px;
}
#summer .announcement-info-container h2{
  font-size: 1.2vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
}
#summer .announcement-info-container p {
  font-size: 0.9vw;
  line-height: 1.4vw;
  font-family: 'Roboto-Light';
  font-weight: 100;
  margin-bottom: 0.5vw;
}
#summer .announcement-title h2{
  font-size: 3vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
}
#summer .announcement-title .header-title {
  font-family: 'Grandis_Medium';
  font-size: 4.8vw;
  font-weight: 100;
}
#summer .announcement-date h3 {
  font-size: 1.3vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
}
#summer .announcement-date p {
  font-size: 1.1vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
}
#summer .FAQ-container {
  font-family: 'Grandis_Medium';
  display: flex;
  padding: 4vw;
  overflow: auto;
}

#summer .FAQ-container .FAQ-container-box {
  margin: 0 auto;
  width: 80%;
}

#summer .ask-box {
  display: flex;
  cursor: pointer;
  font-size: 1.2vw;
  justify-content: space-between;
  padding: 10px;
}
#summer .ask-box p {
  margin-right: 55px;
}
#summer .ask-container {
  transition: 0.8s;
  -webkit-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
}
#summer .inactive {
 display: none
}
#summer .active {
  
}
#summer .static {
  display: block;
}


@media (min-width: 768px) and (max-width: 1024px) {
  #summer .info-text p {
    font-size: 1.6vw;
    margin-bottom: 1.8vw;
    line-height: 2.3vw;
  }
  #summer .announcement-info-container p {
    font-size: 1.3vw;
  }
  #summer .ask-box {
    font-size: 1.8vw;
  }
  #summer .hero .hero-info {
    font-size: 4vw;
    line-height: 5.1vw;
  }
  #summer .info-text h3 {
    font-size: 3vw;
  }
  #summer .announcement-date h3 {
    font-size: 1.8vw;
  }
  #summer .announcement-date p {
    font-size: 1.7vw;
  }
  #summer .header-big {
    font-size: 5.2vw !important;
  }
  #summer .ask-container .answer {
    font-size: 1.5vw;
  }
} 

@media (max-width: 768px) {
  #summer .item-button {
    padding-left: 6vw;
  }
  #summer .hero .hero-info div {
    padding: 8vw;
    font-size: 6vw;
    line-height: 7.8vw;
  }
  #summer .info-text h3 {
    font-family: 'Grandis_Medium';
    font-size: 6vw;
    margin-bottom: 2.3vw;
    text-transform: uppercase;
    font-weight: 100;
  }
  #summer .info-text p {
    font-size: 3.5vw;
    line-height: 4.8vw;
    margin-bottom: 1vw;
  }
  #summer .info-text {
    justify-content: flex-start;
    padding: 10vw;
  }
  #summer .more-button {
    font-size: 3vw;
  }
  #summer .item-action-button span {
    font-size: 6.6vw;
  }
  #summer .switcher-button {
    font-size: 3vw;
    position: inherit;
    top: 8vh;
  }
  #summer .goal-container h3 {
    font-size: 5vw;
  }
  #summer .goal-container p {
    font-weight: 300;
    line-height: 4.4vw;
    font-size: 4vw;
  }
  #summer .hero .hero-info {
    font-size: 5vw;
    line-height: 7.8vw;
  }
  #summer .info-text h3 {
    font-size: 6vw;
    margin-bottom: 2.3vw;
  }
  #summer .ask-box {
    font-size: 3.3vw !important;
  }
  .action-container {
    font-size: 3.3vw !important;
  }
  #summer .ask-container .answer {
    font-size: 3vw;
  }
  #summer .FAQ-container {
    padding: 8vw;
    overflow: auto;
  }
}