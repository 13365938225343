.announcement, .activity, .dailyObject, .quote {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
  width: 100%
}
.dailyObject .row, .activity .row, .video-grid .row {
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
}
.quote {
  flex-direction: column;
  justify-content: space-between;
  padding: 2vw;
}
.quote h2, .quote h4, .quote p {
  font-family: 'Grandis_Medium';
}
.quote p {
  text-transform: uppercase;
}
.quote h4 {
  font-size: 1.4vw;
  font-family: 'Grandis_Light';
}
.quote-place {
  font-size: 1.2vw;
}
.quote-text {
  font-size: 2.2vw;
  font-style: italic;
}
.quote-text-small, .quote-text-small-fix {
  font-size: 2vw;
  font-style: italic;
}
.quote-text-small-fix {
  font-size: 1.6vw;
  font-style: italic;
}

.announcement img {
  border-right: solid 2px black;
}
.video-container {
  background: black;
  display: flex;
  height: 100%;
  width: 100%;
}
.video-container video {
  width: 100%;
  height: auto;
}
.video-button {
  align-items: center;
  border-top: solid 2px black;
  border-left: solid 2px black;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.video-button span {
  font-size: 2.3vw;
}
.video-grid {
  left: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
}
.search-container {
  display: flex;
  height: 100%;
  margin: 0 5%;
}
.close-btn {
  font-size: 2em;
  align-self: center;
}
.result-container {
  height: 100%;
  overflow: auto;
  padding: 4em;
  width: 100%;
}
.result-item {
  margin-bottom: 2em;
}
.result-item h2 {
  font-family: 'Grandis_Light';
  font-size: 0.8vw;
}
.result-item h3 {
  font-family: 'Grandis_Medium';
  font-size: 1.5vw;
  margin-bottom: 3vh;
  transition: all ease 0.5s;
}

.result-item h3:hover {
  color: #ebec8a;
}

.animated-box {
  height: 100%;
  width: 100%;
}
 
.animated-home .block-box, 
.animated-home .quote,
.animated-home .news,
.animated-home .home-shape,
.animated-home .animated-box {
  -webkit-transition: all 1200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
   -moz-transition: all 1200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
     -o-transition: all 1200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
        transition: all 1200ms cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */

-webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
   -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
     -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000); /* easeInOutCubic */
}

.block-box-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.block-box-item {
  border: solid 1px black;
  height: 50%;
  width: 50%;
}

.block-box-item-announcement {
  border: solid 1px black;
  flex: 1;
}

.no-top {
  border-top: none;
}
.no-bottom {
  border-bottom: none;
}
.no-left {
  border-left: none;
}
.no-right {
  border-right: none;
}

.faded {
  opacity: 0;
  -webkit-transition: all 1200ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
  -moz-transition: all 1200ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -o-transition: all 1200ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
       transition: all 1200ms cubic-bezier(0.215, 0.610, 0.355, 1.000); /* easeOutCubic */

-webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -o-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
       transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000); /* easeOutCubic */
}

.text-fix-title {
  font-size: 1.3vw !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .quote h4 {
    font-size: 2vw;
  }
  .quote-text-small, .quote-text-small-fix {
    font-size: 2.3vw;
  }
  .text-fix-title {
    font-size: 2.2vw !important;
  }
}

@media (max-width: 768px) {
  .text-fix-title {
    font-size: 4vw !important;
  }
  .quote h4 {
    font-size: 3.4vw;
  }
  .quote-place {
    font-size: 3.2vw;
  }
  .quote-text {
    font-size: 4.3vw;
  }
  .quote-text-small, .quote-text-small-fix {
    font-size: 3.6vw;
  }
  .result-item h2 {
    font-size: 1.8vw;
  }
  .result-item h3 {
    font-size: 2.5vw;
  }
  .play-container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .video-button {
    align-items: center;
    border-top: solid 2px black;
    border-left: solid 2px black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;
    border-top: none;
  }
  .video-button span {
    font-size: 10.3vw;
  }
}
