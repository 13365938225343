#residence .background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}

#residence .container-block {
  display: flex; 
  flex-direction: column;
  height: 100%;
}

#residence .item {
  flex-grow: 1;
}
#residence .item-button {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}
#residence .item-top {
  border-bottom: 1px solid black;
}
#residence .item-center {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
#residence .item-bottom {
  border-top: 1px solid black;
}
#residence .container {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
}
#residence .column {
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
#residence .container-info {
  align-items: center;
  display: flex;
  border-top: solid 1px black;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
}
#residence .container-data {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-width: 80%;
  justify-content: space-between;
}

#residence .container-data h3 {
  font-family: 'Grandis_Medium';
  font-size: 1.5vw;
}

#residence .container-data p {
  font-family: 'Grandis_Light';
  font-size: 1.3vw;
  line-height: 1.3vw;
}

#residence .container-data {
  align-items: center;
  display: flex;
  flex-direction: row;
}

#residence .container-data span {
  cursor: pointer;
  display: inline-block;
  margin-right: 1vw;
  font-size: 2vw;
}

#residence .container-data .icon-left {
  transform: rotate(180deg);
  margin-right: 3vw;
}
#residence .no-left {
  border-left: none;
}
#residence .no-right {
  border-right: none;
}

#residence .info-box {
  padding: 3vw;
}

#residence .info-box h2 {
  font-family: 'Grandis_Medium';
  font-size: 2.5vw;
  margin-bottom: 2vw;
  text-transform: uppercase;
  font-weight: 100;
}

#residence .info-box p {
  font-family: 'Roboto-Light';
  font-weight: 600;
  line-height: 1.8vw;
  font-size: 1.2vw;
}
#residence .button-container {
  padding: 0 2vw;
  justify-content: space-between;
  width: 80%;
}
#residence .button-container span {
  font-size: 1.5vw;
}
#residence .button-container h2 {
  font-family: 'Grandis_Medium';
  font-size: 1.7vw;
  line-height: 1.7vw;
  text-transform: uppercase;
}

@media (min-width: 768px) and (max-width: 1024px) {
  #residence .info-box p {
    font-size: 1.8vw;
    font-family: 'Roboto-Light';
    line-height: 2.3vw;
    font-weight: 100;
  }
  #residence .container-data h3 {
    font-size: 2vw;
    line-height: 2.1vw;
  }
  
  #residence .container-data p {
    font-size: 2vw;
    margin-top: 1vw;
  }

  #residence .button-container h2 {
    font-size: 2.5vw;
    line-height: 3vw;
    margin-bottom: 1vw;
  }
  #residence .info-box {
    overflow: auto;
  }  
  #residence .info-box h2 {
    font-size: 3vw;
  }
  #residence .button-container span {
    font-size: 3vw;
  }
}

@media (max-width: 768px) {
  #residence .container-data h3 {
    font-size: 3vw;
  }
  #residence .container-data p {
    font-size: 2.6vw;
    line-height: 2.6vw;
  }
  #residence .info-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6vw;
  }
  #residence .info-box h2 {
    font-size: 6vw;
    margin-bottom: 4vw;
  }
  #residence .info-box p {
    line-height: 4.8vw;
    font-size: 3.8vw;
  }
  #residence .button-container h2 {
    font-size: 4.6vw;
    line-height: 4.6vw;
  }
  #residence .container-data p {
    font-size: 3.6vw;
    line-height: 3.6vw;
  }
  #residence .button-container span {
    font-size: 4vw;
  }
  #residence .button-container {
    padding: 0 4vw;
  }
  #residence .container-data span {
    font-size: 6vw;
    margin-left: 1vw;
  }
  
}