.grid-item {
  position: absolute;
  height: 0;
}

body {
  height: 100vh;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

.objects-gird-container {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;
  overflow: scroll;
  width: 100%;
}

.single,
.double {
  border: solid 1px black;
}

.single {
  max-width: 16.66%;
  width: 16.66%;
}

.double {
  max-width: 33.33%;
  width: 33.33%;
}

.font_regular {
  font-family: 'Grandis-Regular' !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .single {
    max-width: 25%;
    width: 25%;
  }

  .double {
    max-width: 50%;
    width: 50%;
  }
}

@media (max-width: 768px) {
  .single {
    max-width: 50%;
    width: 50%;
  }

  .double {
    max-width: 100%;
    width: 100%;
  }
}