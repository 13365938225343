#recovery .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Grandis_Medium';
  height: 100%;
  justify-content: space-between;
  max-height: 100%;
  max-width: 100%;
  padding: 4vw 0;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  font-size: 1.1vw;
}

#recovery .form-container {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  width: 70%;
}

#recovery h2 {
  font-size: 2.5vw;
  width: 70%;
}

#recovery .container input[type='text'],
#recovery .container input[type='password'] {
  background: none !important;
  border: none;
  border-bottom: solid 2px black;
  font-family: 'Grandis_Light';
  font-size: 1.5vw;
  outline: none;
  margin: 1vw 0;
  width: 100%;
}


#recovery .form-btn-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1vw;
}


#recovery .form-btn {
  cursor: pointer;
  margin-right: 2vw;
  text-align: left;
}


#recovery .full-bock {
  height: 100%;
  width: 100%;
}


#recovery ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b0b15a;
  text-transform: uppercase;
}

#recovery :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b0b15a;
  text-transform: uppercase;
}

#recovery ::-ms-input-placeholder { /* Microsoft Edge */
  color: #b0b15a;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  #recovery .container {
    padding: 16vw !important;
  }
  #recovery h2 {
    font-size: 16vw;
  }
  #recovery .form-container {
    max-width: 90%;
    width: 90%;
  }
  #recovery .container input[type='text'],
  #recovery .container input[type='password'] {
    font-size: 5vw;
    margin: 2vw 0;
  }
  #recovery ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 5vw;
  }
  
  #recovery :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 5vw;
  }
  
  #recovery ::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 5vw;
  }
  #recovery .form-btn-container {
    margin-top: 7vw;
  }
  #recovery .form-btn-container .form-btn:nth-child(1) {
    margin-right: 10vw;
  }
  #recovery .pwd-recovery {
    font-size: 3.5vw;
  }
  #recovery .h2-recovery {
    font-size: 11vw;
  }
}

/*

#recovery .btn-container {
  display: flex;
}
#recovery .arrow-btn {
  align-items: center;
  border: 2px solid black;
  cursor: pointer;
  font-size: 3vw;
  display: flex;
  height: 6vw;
  justify-content: center;
  width: 6vw;
}
#recovery .create-btn {
  align-items: center;
  border: 2px solid black;
  cursor: pointer;
  font-size: 1.2vw;
  display: flex;
  height: 6vw;
  justify-content: center;
  padding: 0 2vw;
}
#recovery .create-btn:hover {
  background: #EBEC8B;
}
#recovery .arrow-btn:nth-child(odd) {
  border-right: 1px solid black;
}
#recovery .arrow-btn:nth-child(even) {
  border-left: 1px solid black;
}
#recovery .left-arrow, #recovery .right-arrow {
  display: inline-block;
}
#recovery .left-arrow {
  transform: rotate(180deg);
}
#recovery .text-container {
  width: 60%;
}
#recovery .field-container {
  width: 60%;
}



#recovery .container .select-input {
  border: solid 2px black;
  cursor: pointer;
  padding: 1vw;
  margin: 0;
}

#recovery .select-input-option {
  border: solid black 2px;
  border-top: none;
  cursor: pointer;
  overflow: auto;
  position: absolute;
  width: 60%;
  z-index: 100;
}

#recovery .select-input-option p {
  padding: 0.5vw;
  transition: 0.5s all ease;
}

#recovery .select-input-option p:hover {
  background: #EBEC8B;
}

*/

/*

#recovery.btn-container {
  display: flex;
}
#recovery.arrow-btn {
  align-items: center;
  border: 2px solid black;
  cursor: pointer;
  font-size: 3vw;
  display: flex;
  height: 6vw;
  justify-content: center;
  width: 6vw;
}
#recovery.create-btn {
  align-items: center;
  border: 2px solid black;
  cursor: pointer;
  font-size: 1.2vw;
  display: flex;
  height: 6vw;
  justify-content: center;
  padding: 0 2vw;
}
#recovery.create-btn:hover {
  background: #EBEC8B;
}
#recovery.arrow-btn:nth-child(odd) {
  border-right: 1px solid black;
}
#recovery.arrow-btn:nth-child(even) {
  border-left: 1px solid black;
}
#recovery.left-arrow, #recovery.right-arrow {
  display: inline-block;
}
#recovery.left-arrow {
  transform: rotate(180deg);
}
#recovery.text-container {
  width: 60%;
}
#recovery.field-container {
  width: 60%;
}



#recovery.container .select-input {
  border: solid 2px black;
  cursor: pointer;
  padding: 1vw;
  margin: 0;
}

#recovery.select-input-option {
  border: solid black 2px;
  border-top: none;
  cursor: pointer;
  overflow: auto;
  position: absolute;
  width: 60%;
  z-index: 100;
}

#recovery.select-input-option p {
  padding: 0.5vw;
  transition: 0.5s all ease;
}

#recovery.select-input-option p:hover {
  background: #EBEC8B;
}

*/