#register .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Grandis_Light';
  font-size: 1.2vw;
  height: 100%;
  justify-content: space-between;
  max-height: 100%;
  max-width: 100%;
  padding: 3vw 0;
  text-align: center;
  width: 100%;
}
#register .container-steps {
  display: flex;
  min-height: 30%;
  width: 90%;
}
#register .container-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}
#register .btn-container {
  display: flex;
}
#register .arrow-btn {
  align-items: center;
  border: 2px solid black;
  cursor: pointer;
  font-size: 3vw;
  display: flex;
  height: 6vw;
  justify-content: center;
  width: 6vw;
}

#register .create-btn {
  align-items: center;
  border: 2px solid black;
  cursor: pointer;
  font-size: 1.2vw;
  font-family: 'Grandis_Medium';
  display: flex;
  height: 3vw;
  justify-content: center;
  padding: 0px 2vw;
  text-transform: uppercase;
}

#register .create-btn:hover {
  background: #EBEC8B;
}
#register .arrow-btn:nth-child(odd) {
  border-right: 1px solid black;
}
#register .arrow-btn:nth-child(even) {
  border-left: 1px solid black;
}
#register .left-arrow, #register .right-arrow {
  display: inline-block;
}
#register .left-arrow {
  transform: rotate(180deg);
}
#register .text-container {
  width: 90%;
}
#register .field-container {
  width: 60%;
}
#register .field-container .warning-text {
  color: red;
}

#register .pic-selector,
#register .container .select-input,
#register .container input[type='file'],
#register .container input[type='text'],
#register .container input[type='password'] {
  background: none;
  border: none;
  border-bottom: solid 2px black;
  font-family: 'Grandis_Light';
  cursor: pointer;
  font-size: 1.5vw;
  outline: none;
  margin: 1vw 0;
  width: 100%;
}

.checkbox-button {
  cursor: pointer;
}
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
.checkbox-button__input {
  opacity: 0;
  position: absolute;
}

.checkbox-button__control {
  border: 2px solid black;
  display: inline-block;
  max-height: 1vw;
  min-height: 1vw;
  margin: 0 0.5vw;
  position: relative;
  max-width: 1vw;
  min-width: 1vw;
}

.checkbox-button__control span {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
}

.checkbox-button__input:checked+.checkbox-button__control span:after {
  background: black;
  content: '';
  display: block;
  height: 0.5vw;
  width: 0.5vw;
}

@media (max-width: 768px) {
  #register .container {
    font-size: 5vw;
    padding-bottom: 10vw;
    padding-top: 10vw;
  }
  #register .field-container-text {
    margin-bottom: 20vw;
  }
  #register .create-btn {
    font-size: 5vw;
    font-family: 'Grandis_Medium';
    display: flex;
    height: 15vw;
    justify-content: center;
    padding: 2vw 15vw;
    text-transform: uppercase;
  }
  #register .arrow-btn {
    font-size: 5vw;
    height: 15vw;
    justify-content: center;
    width: 15vw;
}
  #register .text-container {
    width: 80%;
  }
  #register .field-container {
    width: 80%;
  }
  #register h3 {
    margin-bottom: 4vw;
  }
  #register .container .select-input,
  #register .container input[type='file'],
  #register .container input[type='text'],
  #register .container input[type='password'] {
    font-size: 5vw;
  }
  #register ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 5vw;
  }
  
  #register :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 5vw;
  }
  
  #register ::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 5vw;
  }
  #register .field-container p {
    text-align: center;
    font-size: 4vw;
    margin-top: 2vw;
  }
  #register .pic-selector {
    font-size: 4vw;
  }
  #register .container-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    padding: 8vw 0;
    width: 100%;
  }
  #register .container-step {
    width: 80%;
  }
  #register .container h2 {
    font-size: 4.8vw;
    line-height: 8vw;
  }
}

/*


  bottom: 0;
  
  
  
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  




input {
    font-size: 1rem;
    line-height: 1.5;
    
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
}



.checkbox-button__control {
    
    
    
    
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;
    color: #017b5f;
    
}



.checkbox-button__input:checked+.checkbox-button__control {
    border-color: black;
}


*/

#register .select-input-container {
  position: relative;
}

#register .container .select-input {
  border: solid 2px black;
  cursor: pointer;
  padding: 1vw;
  margin: 0;
  position: relative;
}

#register .select-input-option {
  border: solid black 2px;
  border-top: none;
  cursor: pointer;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 100;
}

#register .select-input-option p {
  padding: 0.5vw;
  transition: 0.5s all ease;
}

#register .select-input-option p:hover {
  background: #EBEC8B;
}