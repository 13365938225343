/* Grid */
#pes-about .column {
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
#pes-about .background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}
#pes-about .content-container {
  border-right: 1px solid black;
  border-bottom: 2px solid black;
  height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100px;
}

#pes-about .content-padding {
  padding: 0 5vw;
  margin-top: 5vw;

}
.content-padding-first {
  padding: 0 5vw;
  margin-top: 7vw;
}
#goals-pes, #about-summer, 
  .active-tutor, #requirements-summer, 
  #academic_training, #housing-summer,
   #financialAID-summer{
  margin-top: 2vw;
}
#goals-pes div {
  display: flex;
  flex-wrap: wrap;
}
#pes-about .padding-right {
  padding-right: 2vw !important;
}
#pes-about .objective-item {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1vw;
  width: 33%;
}
#pes-about .content-container p {
  font-size: 1.2vw;
  line-height: 1.8vw;
  font-family: 'Roboto-Light';
  font-weight: 600;
}
#pes-about .objective-item h3 {
  font-family: 'Grandis_Medium';
  font-size: 2.3vw;
  line-height: 2vw;
}
#pes-about .objective-item p {
  width: 84%;
}
#pes-about .content-container h2 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  margin-bottom: 2vw;
  text-transform: uppercase;
  font-weight: 100;
}

#pes-about .content-container #info-pes {
  display: flex;
  flex-direction: row;
}
#pes-about .content-container .content-column{
  padding-right: 4vw;
  width: 50%;
}

#pes-about .border-right {
  border-right: 2px solid black;
}
#pes-about .column-display {
  display: flex;
  flex-direction: column;
}
#pes-about .row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  width: 100%;
}
#pes-about .item {
  flex-grow: 1;
}
#pes-about .item-button {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}
#pes-about .item-top {
  border-bottom: 1px solid black;
}
#pes-about .item-center {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
#pes-about .item-bottom {
  border-top: 1px solid black;
}
#pes-about .item-left {
  border-right: 1px solid black;
}
#pes-about .item-center-row {
  border-right: 1px solid black;
  border-left: 1px solid black;
}
#pes-about .item-right {
  border-left: 1px solid black;
}
#pes-about .no-left {
  border-left: none;
}
#pes-about .no-right {
  border-right: none;
}

#pes-about .no-bottom {
  border-bottom: none;
}
#pes-about .tutor-action {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.3vw;
}
#pes-about .tutor-action h2 {
  margin-bottom: 0;
  max-width: 65%;
}
#pes-about  .arrow-btn {
  display: inline-block;
}
#pes-about  .arrow-btn:nth-child(odd) {
  margin-right: 1em;
  transform: rotate(180deg);
}
#pes-about  .arrow-btn:nth-child(even) {
  margin-left: 1em;
}
#pes-about .container {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
}

#pes-about .hero {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}
#pes-about .hero .hero-info {
  align-items: center;
  display: flex;
  bottom: 0;
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  justify-content: flex-end;
  left: 0;
  position: absolute;
}
#pes-about .hero .hero-info div {
  padding: 4vw;
}


#pes-about .info-text {
  height: 100%;
  padding: 4vw;
  width: 100%;
}
#pes-about .info-text h3 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  margin-bottom: 3vw;
}
#pes-about .info-text p {
  font-family: 'Grandis_Light';
  font-size: 1.3vw;
  margin-bottom: 1vw;
}


#pes-about .info-container {
  display: flex;
  height: 100%;
  width: 100%;
}

#pes-about .announcement {
  height: 100%;
  width: 100%;
}

#pes-about .row-border {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin-top: 3vw;
}

#pes-about .grid-announcement-image {
  border-bottom: 1px solid black;
  display: flex;
  flex-grow: 2;
}
#pes-about .grid-announcement-block {
  border-top: 1px solid black;
  display: flex;
  flex-grow: 1;
}

#pes-about .container-announcement {
  flex-direction: column;
  overflow: hidden;
}

#pes-about .grid-announcement-block {
  border-top: 1px solid black;
}
#pes-about .announcement-info-container {
  border-right: 1px solid black;
  bottom: 0;
  height: 100px;
  left: 0;
  position: absolute;
  width: 100px;
}
.tutor-name {
  font-size: 1.5vw !important;
  text-transform: capitalize !important;
}

#pes-about img {
  width: 100%;
}

.download-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: 'Grandis_Medium';
  font-size: 1vw;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.download-btn span, .download-btn span:hover {
  color: black;
  display: inline-block;
  font-size: 1.5vw;
  margin-left: 0.5vw;
  transform: rotate(90deg);
}

#pes-about strong {
  font-family: 'Roboto-Regular' !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  #pes-about .content-padding-first,
  #pes-about .content-padding {
    padding: 0 12vw;
    margin-top: 7vw;
  }
  #goals-pes h2 {
    margin-bottom: 4vw !important;
  }
  #pes-about .objective-item h3 {
    font-size: 4.3vw;
    line-height: 4vw;
  }
  #pes-about .objective-item p {
    width: 80%;
  }
  #pes-about .content-container h2 {
    font-size: 3vw;
    margin-bottom: 2.5vw;
  }
  #pes-about .content-container p {
    font-size: 1.6vw;
    line-height: 2.3vw;
  }
  li {
    font-size: 1.6vw;
    line-height: 2.3vw;
  }
  .testimonials-container h3 {
    font-size: 2.5vw;
  }
  #pes-about .tutor-action h2 {
    font-size: 2vw !important;
    line-height: 2vw;
    margin-bottom: 0;
  }
  .row-border {
    margin-top: 8vw;
  }
}

@media (max-width: 768px) {
  #pes-about .content-container h2 {
    font-size: 5vw;
    margin-bottom: 2.5vw;
  }
  #pes-about .content-container p {
    font-size: 3.5vw;
    line-height: 4.8vw;
  }
  li {
    font-size: 3.5vw;
    line-height: 4.8vw;
  }
  .testimonials-container h3 {
    font-size: 2.5vw;
  }
  #about-pes, #tutors-pes {
    margin-top: 2vw;
  }
}

#pes-about .no-top {
  border-top: none !important;
  margin-top: 0 !important;
}

/*



.grid-space, .background-container, .grid-announcement {
  display: flex;
}
.background-container{
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.grid-space{
  flex-grow: 2;
}




.grid-announcement {
  flex-direction: column;
  flex-grow: 3;
}






*/