#myAccount {
  font-family: 'Grandis_Medium';
}

#myAccount .filter-button{
  align-items: center;
  cursor: pointer;
  font-size: 1.2vw;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  transition: 0.8s all ease;
}

#myAccount .filter-button:hover {
  background: #EBEC8B !important;
}

#myAccount .user-container {
  height: 100%;
  width: 100%;
}

#myAccount .user-data-container {
  font-size: 1vw;
  height: 100%;
  padding: 2vw 3vw;
  width: 100%;
}
#myAccount .featured-object {
  background: rgba(255, 255, 255, 0.3);
  display: inline-block;
  padding: 0.2vw 0.5vw;
  margin-top: 0.5vw;
  background: #8ba9e5;
  padding: 0.2vw 0.5vw;
  font-size: 1vw !important;
  font-family: 'Grandis_Medium';
text-transform: capitalize;
}
#myAccount .select-icon {
  font-size: 3vw;
}

#myAccount .user-data-container h4 {
  font-size: 1.3vw;
}
#myAccount .user-data-container h2 {
  font-size: 2vw;
}
#myAccount .data-block {
  margin-top: 1.2vw;
}

#myAccount .item-button {
  border: solid 1px black;
  border-top: solid 2px black;
  border-bottom: none;
}

#myAccount .no-left {
  border-left: none;
}

#myAccount .no-right {
  border-right: none;
}

#myAccount .buttons-container {
  display: flex;
}

#myAccount .info-block {
  align-items: center;
  display: flex;
  font-size: 1.2vw;
  flex-direction: column;
  justify-content: center;
}

#myAccount .info-block h3 {
  text-transform: uppercase;
}

#myAccount .info-block p {
  font-family: 'Grandis_Light';
}

#myAccount .info-block span {
  font-size: 3vw;
  margin-bottom: 2vw;
}

#myAccount .info-block span {
  font-size: 3vw;
}

#myAccount .info-block div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 45%;
  width: 60%;
}

#myAccount .object-container {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;
  overflow: scroll;
  width: 100%;

  
}

#myAccount .object-btn {
  border: solid 1px black;
  min-width: 20%;
  width: 20%;
}

#myAccount .user-data-container .select-input,
#myAccount .user-data-container input[type='file'],
#myAccount .user-data-container input[type='text'],
#myAccount .user-data-container input[type='password'] {
  background: none;
  border: none;
  border-bottom: solid 2px black;
  font-family: 'Grandis_Light';
  font-size: 1.5vw;
  outline: none;
  margin: 1vw 0;
  width: 100%;
}

#myAccount .user-data-container .select-input {
  border: solid 2px black;
  cursor: pointer;
  padding: 1vw;
  margin: 0;
}

#myAccount .select-input-option {
  border: solid black 2px;
  border-top: none;
  cursor: pointer;
  overflow: auto;
  position: absolute;
  width: 60%;
  z-index: 100;
}

#myAccount .unstyled {
  margin-top: 8vh;
  padding-left: 0;
}

#myAccount .select-input-option p {
  padding: 0.5vw;
  transition: 0.5s all ease;
}

#myAccount .select-input-option p:hover {
  background: #EBEC8B;
}

#myAccount .margin-top {
  margin-top: 1vw;
}

#myAccount .form-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
#myAccount .form-container p {
  border: solid 2px black;
  background: #EBEC8B;
  cursor: pointer;
  font-family: 'Grandis_Medium';
  font-size: 0.9vw;
  padding: 0.7vw 1vw;
  text-transform: uppercase;
}
#myAccount .form-container p:nth-child(even) {
  border-left: solid 1px black;
}
#myAccount .form-container p:nth-child(odd) {
  border-right: solid 1px black;
}
#myAccount .form-container p:hover {
  background: #EBEC8B;
}
#myAccount .message {
  font-size: 1vw;
  margin-top: 0.5vw;
  text-align: center;
}

#myAccount .button {
  align-items: center;
  color: #EBEC8B;
  cursor: pointer;
  display: flex;
  margin-top: 1vw;
}
#myAccount .button span {
  color: #EBEC8B;
  margin-left: 0.4vw;
  transform: scale(0.7);
}



@media (max-width: 1024px) {
  #myAccount .object-btn {
    border: solid 1px black;
    min-width: 33.33%;
    width: 33.33%;
  }
}

@media (max-width: 768px) {
  #myAccount .object-btn {
    border: solid 1px black;
    min-width: 50%;
    width: 50%;
  }
  .form-btn {
    font-size: 3vw;
  }
  
  #myAccount .filter-button{
    align-items: center;
    cursor: pointer;
    font-size: 1.2vw;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  
  #myAccount .user-container {
    height: 100%;
    width: 100%;
  }
  
  #myAccount .user-data-container {
    font-size: 5.6vw;
    height: 100%;
    padding: 4vw 3vw;
    width: 100%;
  }
  
  #myAccount .select-icon {
    font-size: 3vw;
  }
  
  #myAccount .user-data-container h4 {
    font-size: 10.6vw;
  }
  #myAccount .user-data-container h2 {
    font-size: 8.2vw;
  }
  #myAccount .data-block {
    margin-top: 6.4vw;
  }
  
  #myAccount .item-button {
    border: solid 1px black;
    border-bottom: solid 2px black;
    border-top: none;
  }
  
  #myAccount .no-left {
    border-left: none;
  }
  
  #myAccount .no-right {
    border-right: none;
  }
  
  #myAccount .buttons-container {
    display: flex;
  }
  
  #myAccount .info-block {
    align-items: center;
    display: flex;
    font-size: 4vw;
    flex-direction: column;
    justify-content: center;
  }

  #myAccount .info-block div {
    height: 80%;
    padding: 8vw 0;
    width: 80%;
  }
  
  #myAccount .info-block span {
    font-size: 10vw;
    margin-bottom: 2vw;
  }

  #myAccount .user-data-container .select-input,
  #myAccount .user-data-container input[type='file'],
  #myAccount .user-data-container input[type='text'],
  #myAccount .user-data-container input[type='password'] {
    font-size: 3vw;
    margin: 2vw 0;
  }
  
  #myAccount .user-data-container .select-input {
    margin: 0;
  }
  
  #myAccount .select-input-option p {
    padding: 1vw;
  }
  #myAccount .margin-top {
    margin-top: 2vw;
  }
  #myAccount .form-container p {
    font-family: 'Grandis_Medium';
    font-size: 3vw;
    padding: 1vw 3vw;
  }
  #myAccount .message {
    font-size: 2vw;
    margin-top: 1vw;
  }
  #myAccount .mobile-menu {
    border-left: 1px solid black;
    position: relative;
    width: 100%;
  } 
  #myAccount .option-button {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    border-bottom: solid 2px black;
  }
  #myAccount .option-menu {
    border: solid 2px black;
    border-right: none;
    border-left: none;
    border-top: none;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 100;
    padding: 1vw 2vw;
    padding-bottom: 0;
  }
  #myAccount .option-menu p {
    padding-bottom: 2vw;
  }
  .option-button span {
    display: inline-block;
    margin-left: 2vw;
  }
  .option-button .up {
    transform: rotate(270deg);
  }
  .option-button .down {
    transform: rotate(90deg);
  }
  #myAccount .featured-object {
    font-size: 4vw !important;
  }
  .user-data-container input, .select-input{
    font-size: 5vw !important;
  }
  #myAccount .object-container .mobile-menu {
    position: absolute;
    z-index: 100;
  }
}