#calendar {
  display: flex;
  height: 100%;
  width: 100%;
}
.calendar-mobile {
  flex-direction: column;
}
#calendar .side-menu {
  border-right: solid black 2px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

#calendar .calendar-item-block{
  flex: 1;
} 

#calendar .calendar-items-container {
  width: 100%;
  overflow: auto; 
}

#calendar .calendar-filter-container {
  position: relative;
  border-bottom: solid black 2px;
  width: 100%;
}

#calendar .last-years-btn {
  align-items: flex-end !important;
  display: flex;
  margin-top: 2vw;
  text-transform: uppercase;
  justify-content: flex-start !important;
  width: 50%;
}

#calendar .last-years-btn div {
  align-items: flex-start !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
}

#calendar .last-years-btn span {
  margin-bottom: 0.2vw;
}

#calendar .last-years-btn p {
  font-family: 'Grandis_Medium';
  transition: all 0.5s ease;
}

#calendar .last-years-btn:hover p , #calendar .last-years-btn:hover span {
  color: #ebec8a;
}

#calendar .wednesday-item {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

#calendar .wednesday-item-container {
  font-family: 'Grandis_Medium';
  max-width: 80%;
  width: 80%;
}

#calendar .wednesday-item-container h2 {
  font-size: 1.5vw;
  font-weight: 100;
  margin-bottom: 4vw;
  text-transform: uppercase;
}
#calendar .wednesday-item-container h3 {
  font-size: 1.1vw;
  font-weight: 100;
  text-transform: uppercase;
}
#calendar .wednesday-item-container h4 {
  font-family: 'Grandis_Light';
  font-size: 0.9vw;
  font-weight: 100;
  margin-bottom: 2vw;
}
#calendar .wednesday-item-container p {
  font-family: 'Roboto-Regular';
  font-size: 0.9vw;
}

#calendar .calendar-row {
  display: flex;
}

#calendar .background-hover {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}
#calendar .bakcground-overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
#calendar .bakcground-overlay div {
  display: flex;
  font-size: 2vw;
  justify-content: space-between;
  width: 40%;
} 

#calendar .bakcground-overlay div span {
  cursor: pointer;
} 

#calendar .calendar-filter-button {
  width: 100%;
}


#calendar .calendar-item-content .calendar-date {
  font-family: 'Grandis_Medium';
  font-size: 0.8vw;
  font-weight: 100;
  margin-bottom: 0.9vw;
}

#calendar .calendar-filter-options {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto-Regular';
  font-size: 1vw;
  justify-content: center;
  padding: 10%;
  width: 100%;
}
#calendar .calendar-filter-options div {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 1vw;
}

#calendar .calendar-checkbox {
  border: 2px solid black;
  height: 0.9vw;
  margin-bottom: 0 !important;
  margin-right: 1vw;
  width: 0.9vw;
}

#calendar .calendar-active-filter {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
#calendar .calendar-active-filter div {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: 'Grandis_Medium';
  font-size: 1.2vw;
  justify-content: space-between;
  width: 60%;
}
#calendar .calendar-active-filter{
  font-size: 1.3vw;
  text-transform: uppercase;
}
#calendar .calendar-active-filter span {
  display: inline-block;
  font-size: 1.4vw;
  transition: ease-in all 0.5s;
  transform: rotate(90deg);
}
#calendar .calendar-active-filter .active {
  transform: rotate(270deg);
}

#calendar .no-top {
  border-top: none;
}
#calendar .no-left {
  border-left: none;
}
#calendar .no-right {
  border-right: none;
}

#calendar .calendar-month {
  border-bottom: 2px solid black;
  font-family: 'Grandis_Medium';
  font-size: 1.1vw;
  height: 60px;
  text-transform: uppercase;
}
#calendar .calendar-month-title-single {
  align-items: center;
  border-right: 2px solid black;
  display: flex;
  font-family: 'Grandis_Medium';
  font-weight: 300;
  justify-content: center;
  height: 100%;
}

#calendar .row {
  display: flex;
  flex-direction: row;
}

#calendar .calendar-month-title {
  align-items: center;
  border-right: 2px solid black;
  display: flex;
  font-family: 'Grandis_Medium';
  flex-direction: column;
  font-weight: 300;
  justify-content: center;
  height: 100%;
}
#calendar .calendar-month-title h2, #calendar .last-years-btn h2 {
  font-family: 'Grandis_Medium';
  font-size: 1.3vw;
  font-weight: 300;
}
#calendar .calendar-item {
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: row;
  width: 100%;
}
#calendar .calendar-item-container {
  align-items: center;
  display: flex;
  padding: 2vw 3vw;
  width: 100%;
}
#calendar .calendar-item-content {
  font-family: 'Grandis_Medium';
  height: 100%;
  max-width: 90%;
  min-width: 90%;
}
#calendar .calendar-item-option {
  display: flex;
  flex-direction: column;
  font-size: 2.3vw;
  justify-content: space-between;
  height: 90%;
} 

#calendar .calendar-item-option .icon-04 {
  cursor: pointer;
  transition: all 0.5s ease;
} 

#calendar .calendar-item-option .icon-04:hover {
  color: #ebec8a;
  transform: scale(1.2);
} 

#calendar .calendar-item-content h2 {
  font-size: 2.5vw;
  font-weight: 100;
  text-transform: uppercase;
  max-width: 90%;
}

#calendar .calendar-item-content h3 {
  font-family: 'Grandis_Light';
  font-size: 1.5vw;
  font-weight: 100;
}
#calendar .calendar-item-tag {
  align-items: center;
  display: flex;
  flex-direction: row;
}
#calendar .box {
  background: rgba(255, 255, 255, 0.3);
  max-height: 0.6vw;
  max-width: 0.6vw;
  height: 0.5vw;
  width: 0.5vw;
  margin-right: 10px;
}
#calendar .calendar-item-content span {
  font-size: 1vw;
  font-family: 'Grandis_Medium';
}

#calendar .form-value form {
  align-items: center;
  display: flex;
  flex-direction: row;
  border-bottom: solid 2px black;
  max-height: 60px;
  padding-left: 2vw;
  font-size: 1vw;
}
/*
  align-items: center;
  display: flex;
  
  justify-content: center;
  text-transform: uppercase;
*/

#calendar .slim-block .calendar-item-tag span {
  font-size: 0.9vw;
}

#calendar .slim-block h2 {
  font-size: 1.8vw;
}

#calendar .slim-block h3 {
  font-size: 1vw;
}

#calendar .slim-block .calendar-month-title  h2 {
  font-size: 1.3vw;
}

#calendar .slim-block .calendar-item-option {
  align-items: center;
  flex-direction: row;
  width: 100%;
}



#calendar .calendar-item-content a h2 {
  transition: all 0.6s ease;
}

#calendar .calendar-item-content a h2:hover {
 color: #ebec8a;
}

@media (min-width: 768px) and (max-width: 1024px) {
  #calendar .calendar-active-filter div {
    font-size: 2vw;
  }
  #calendar .calendar-month-title-single {
    font-size: 1.5vw;
  }
  #calendar .calendar-month-title h2, #calendar .last-years-btn h2 {
    font-size: 1.6vw;
  }
  #calendar .calendar-item-content span {
    font-size: 1.2vw;
  }
  #calendar .calendar-item-content h2 {
    font-size: 4vw;
  }
  #calendar .calendar-item-content h3 {
    font-size: 1.7vw;
  }
  #calendar .calendar-item-option {
    font-size: 3vw;
  }
  #calendar .wednesday-item-container h2 {
    font-size: 2vw;
  }
  #calendar .wednesday-item-container h3 {
    font-size: 1.5vw;
  }
  #calendar .wednesday-item-container h4 {
    font-size: 1.2vw;
    font-weight: 100;
    margin-bottom: 2vw;
  }
  #calendar .wednesday-item-container p {
    font-family: 'Roboto-Light';
    font-size: 1.2vw;
    line-height: 1.5vw;
  }

  #calendar .slim-block .calendar-item-tag span {
    font-size: 0.9vw;
  }
  
  #calendar .slim-block h2 {
    font-size: 2vw;
  }
  
  #calendar .slim-block h3 {
    font-size: 1.2vw;
  }
  
  #calendar .slim-block .calendar-month-title  h2 {
    font-size: 1.5vw;
  }
}


@media (max-width: 768px) {
  #calendar .wednesday-item-container h2 {
    font-size: 6vw;
    margin-bottom: 4vw;
  }
  #calendar .wednesday-item-container h3 {
    font-size: 4.4vw;
  }
  #calendar .wednesday-item-container h4 {
    font-size: 4vw;
  }

  #calendar .calendar-item-content h2 {
    font-size: 4.5vw;
  }
  #calendar .calendar-item-content h3 {
    font-size: 3.5vw;
    margin-bottom: 3vw;
  }
  #calendar .calendar-item-content p {
    font-size: 2.5vw;
  }
  #calendar .calendar-item-content .calendar-date {
    font-size: 2.5vw;
  }
  #calendar .calendar-item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  #calendar .calendar-active-filter div {
    font-size: 4vw;
    justify-content: center;
  }
  #calendar .calendar-active-filter span {
    font-size: 3vw;
    margin-left: 2vw;
  }
  #calendar .calendar-filter-options {
    font-size: 4vw;
  }
  #calendar .calendar-checkbox {
    height: 4vw;
    margin-right: 2vw;
    width: 4vw;
  }
  #calendar .calendar-month {
    font-size: 4vw;
  }
  #calendar .form-value form {
    font-size: 4vw;
    padding-left: 4vw;
  }
  #calendar .calendar-month-title h2, #calendar .last-years-btn h2 {
    font-size: 4.3vw;
  }
  #calendar .calendar-item-content span {
    font-size: 4vw;
  }
  #calendar .calendar-item-option {
    font-size: 5vw;
  }
  #calendar .box {
    max-height: 1.5vw;
    max-width: 1.5vw;
    height: 2vw;
    width: 2vw;
  }
}
/*

  display: flex;
  flex-wrap: wrap;
  
  max-height: 100%;
  height: 100%;
  width: 100%;

*/