#not-found {
  font-family: 'Grandis_Light';
  font-weight: 100;
}

#not-found .color-block {
  height: 100%;
  width: 100%;
}

#not-found .block {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

#not-found .text {
  align-items: center;
  font-size: 2vw;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

#not-found .error {
  align-items: center;
  font-size: 6vw;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}


@media (min-width: 768px) and (max-width: 1024px) {

}

@media (max-width: 768px) {
  #not-found .error {
    font-size: 25vw;
  }
  #not-found .error p {
    font-size: 15vw;
  }
}