#login .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Grandis_Medium';
  height: 100%;
  justify-content: space-between;
  max-height: 100%;
  max-width: 100%;
  padding: 4vw 0;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  font-size: 1.1vw;
}

#login .warning-text {
  color: red;
}

#login .form-container {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  width: 70%;
}

#login .container input[type='text'],
#login .container input[type='password'] {
  background: none !important;
  border: none;
  border-bottom: solid 2px black;
  font-family: 'Grandis_Light';
  font-size: 1.5vw;
  outline: none;
  margin: 1vw 0;
  width: 100%;
}


#login .form-btn-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1vw;
}



#login .form-btn {
  cursor: pointer;
  margin-right: 2vw;
  text-align: left;
}


#login .full-bock {
  height: 100%;
  width: 100%;
}


#login ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b0b15a;
}

#login :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b0b15a;
}

#login ::-ms-input-placeholder { /* Microsoft Edge */
  color: #b0b15a;
}

#login .pwd-recovery p {
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1024px) {
  #login .container {
    font-size: 1.8vw;
  }
  #login h2 {
    font-size: 3vw;
  }
  #login ::placeholder { 
    font-size: 1.8vw;
  }
  
  #login :-ms-input-placeholder {
    font-size: 1.8vw;
  }
  
  #login ::-ms-input-placeholder {
    font-size: 1.8vw;
  }
}

@media (max-width: 768px) {
  #myAccount .object-container .mobile-menu {
    position: fixed;
    z-index: 100;
  }
  .option-menu {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .option-menu p {
    line-height: 8vw;
  }
}




/*



  #login .container {
    padding: 1.2vw !important;
  }
  
  #login .form-container {
    max-width: 90%;
    width: 90%;
  }
  #login .container input[type='text'],
  #login .container input[type='password'] {
    font-size: 5vw;
    margin: 2vw 0;
  }
  
  #login .form-btn-container {
    margin-top: 7vw;
  }
  #login .form-btn-container .form-btn:nth-child(1) {
    margin-right: 10vw;
  }
  #login .pwd-recovery {
    font-size: 3.5vw;
  }
  #login .h2-recovery {
    font-size: 11vw;
  }

*/

@media (max-width: 768px) {
  #login .container {
    font-size: 3.8vw;
  }

  #login .container {
    padding: 16vw !important;
  }
  #login h2 {
    font-size: 16vw;
  }
  #login .form-container {
    max-width: 90%;
    width: 90%;
  }
  #login .container input[type='text'],
  #login .container input[type='password'] {
    font-size: 5vw;
    margin: 2vw 0;
  }
  #login ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 5vw;
  }
  
  #login :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 5vw;
  }
  
  #login ::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 5vw;
  }
  #login .form-btn-container {
    margin-top: 7vw;
  }
  #login .form-btn-container .form-btn:nth-child(1) {
    margin-right: 10vw;
  }
  #login .pwd-recovery {
    font-size: 3.5vw;
  }
  #login .h2-recovery {
    font-size: 11vw;
  }
}

/*

#login .btn-container {
  display: flex;
}
#login .arrow-btn {
  align-items: center;
  border: 2px solid black;
  cursor: pointer;
  font-size: 3vw;
  display: flex;
  height: 6vw;
  justify-content: center;
  width: 6vw;
}
#login .create-btn {
  align-items: center;
  border: 2px solid black;
  cursor: pointer;
  font-size: 1.2vw;
  display: flex;
  height: 6vw;
  justify-content: center;
  padding: 0 2vw;
}
#login .create-btn:hover {
  background: #EBEC8B;
}
#login .arrow-btn:nth-child(odd) {
  border-right: 1px solid black;
}
#login .arrow-btn:nth-child(even) {
  border-left: 1px solid black;
}
#login .left-arrow, #login .right-arrow {
  display: inline-block;
}
#login .left-arrow {
  transform: rotate(180deg);
}
#login .text-container {
  width: 60%;
}
#login .field-container {
  width: 60%;
}



#login .container .select-input {
  border: solid 2px black;
  cursor: pointer;
  padding: 1vw;
  margin: 0;
}

#login .select-input-option {
  border: solid black 2px;
  border-top: none;
  cursor: pointer;
  overflow: auto;
  position: absolute;
  width: 60%;
  z-index: 100;
}

#login .select-input-option p {
  padding: 0.5vw;
  transition: 0.5s all ease;
}

#login .select-input-option p:hover {
  background: #EBEC8B;
}

*/