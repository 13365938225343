#filter-screen .container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: auto;
}

#filter-screen .filters-block {
  width: 100%;
}

#filter-screen .filters-container {
  border: solid 1px black;
}

#filter-screen .filter-input-container {
  border: solid 1px black;
}

#filter-screen .filter-input-container form {
  align-items: center;
  display: flex;
  font-size: 1.5vw;
  padding: 2vw 4vw;
  height: 100%;
  width: 100%;
}

.title-container p {
  font-family: 'Grandis_Medium';
}

.filter-input-container-bottom {
  font-size: 1.4vw;
}

#filter-screen .filter-input-container span {
  font-size: 1.9vw;
}

#filter-screen input {
  font-family: 'Roboto-Regular';
  font-size: 1.2vw;
}

#filter-screen input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1;
  /* Firefox */
  font-size: 1.2vw !important;
}

#filter-screen input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
  font-size: 1.2vw !important;
}

#filter-screen .filter-select-container {
  display: flex;
}

#filter-screen .filter-item-container {
  border: solid 1px black;
  flex: 1;
  position: relative;
  font-family: 'Grandis_Light';
  font-size: 0.9vw;
  text-transform: uppercase;
}

#filter-screen .filter-item-tag {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0 2vw;
}

#filter-screen .filter-item-tag:hover {
  background-color: #ebec8a !important;
}

#filter-screen .filter-item-tag span {
  display: inline-block;
  margin-left: 10px;
  transform: rotate(90deg);
}

#filter-screen .filter-item-tag .active {
  transform: rotate(270deg);
}

#filter-screen .filter-item-tag-container {
  border: solid 2px black;
  left: -2px;
  overflow: auto;
  position: absolute;
  right: -2px;
  z-index: 1000;
}

#filter-screen .filter-item-tag-container p {
  padding: 1vw 2vw;
  text-transform: none;
}

#filter-screen .filter-button-container {
  display: flex;
  font-family: 'Grandis_Medium';
  font-size: 1vw;
}

#filter-screen .filter-button-container p {
  cursor: pointer;
}

#filter-screen .filter-text {
  align-items: flex-end;
  display: flex;
  padding: 0 1vw;
  width: 50%;
}

#filter-screen .filter-buttons {
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 0.9vw;
}

#filter-screen .filter-buttons p {
  border: solid 2px black;
  margin-left: 1vw;
  padding: 0.7vw 1vw;
}

#filter-screen .filter-buttons p:hover {
  background-color: #ebec8a;
}

#filter-screen .filter-suggestion-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2vw;
}

#filter-screen .suggestion-box,
#filter-screen .suggestion-banner {
  border: solid 1px black;
}

#filter-screen .suggestion-box {
  cursor: pointer;
}

#filter-screen .suggestion-content {
  align-items: center;
  border: solid 1px black;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1vw;
  font-family: 'Grandis_Medium';
  font-size: 1vw;
}

#filter-screen .program-tag {
  display: flex;
  flex-direction: row;
}

.container-data-image {
  position: relative;
}

.container-data-image .object-date {
  bottom: 0;
  position: absolute;
  z-index: 10;
  right: 0;
  padding: 5px;
  color: whitesmoke;
  font-family: 'Grandis_Medium';
}

.filters-main-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.filter-box {}

.filter-selector-container {
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  flex-direction: row;
}

.filter-selector {
  align-items: center;
  border: 1px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
}

.filter-selector p {
  text-align: center;
  font-family: 'Grandis_Light';
  font-size: 1vw;
  text-transform: uppercase;
}

.filter-selector span {
  display: inline-block;
  font-size: 1.5vw;
  margin-left: 10px;
  transform: rotate(90deg);
}

.filter-selector span:hover {
  transform: rotate(90deg);
}

.filter-selector .active {
  transform: rotate(270deg);
}

.filter-input-container {
  align-items: center;
  border: 2px solid black;
  border-bottom: 1px solid black;
  display: flex;
  padding: 0;
  position: relative;
}

.filter-input-container form {
  align-items: center;
  display: flex;
  width: 100%;
}

.filter-button-container {
  display: flex;
  flex-direction: row;
  font-family: 'Grandis_Light';
  font-size: 0.9vw;
}

.filter-button-container .one {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-bottom: 1vw;
}

.filter-button-container .one p {
  font-family: 'Grandis_Medium';
  font-size: 1vw;
}

.filter-button-container .two {
  font-family: 'Grandis_Medium';
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  text-transform: uppercase;
}

.filter-button-container .two p {
  cursor: pointer;
  border: solid 2px black;
  margin-left: 2vw;
  padding: 0.7vw 1vw;
}

.filter-suggestion-container {
  display: flex;
}

.filter-suggestion-item {
  border: solid 2px black;
  margin-right: 10px;
}

.filter-suggestion-data {
  border-top: solid 3px black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  font-family: 'Grandis_Medium';
  font-size: 1vw;
}

.filter-container {
  padding: 10px;
  overflow: auto;
  position: absolute;
  width: calc(100% + 8px);
  border: solid 2px black;
  border-top: none;
  left: -4px;
  right: -4px;
}

.filter-container p {
  font-family: 'Grandis_Light';
  font-size: 1vw;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-align: left;
}

.content-container-objects {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}

.container-object {
  border: solid 1px black;
  border-left: none;
  border-right: none;
  display: flex;
  flex-direction: row;
}

.option-data-column {
  font-size: 2.3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1vw 0;
}

.option-data-row {
  align-items: center;
  font-size: 2vw;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 10%;
}

.container-data-image {
  border-right: solid 1px black;
  background-size: cover !important;
  background-position: center center !important;
}

.container-data {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 2vw 4vw;
}

.programs-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.programs-container span {
  margin-right: 20px;
}

.programs-container .text,
.programs-container .text-tag {
  font-family: 'Grandis_Medium';
  font-size: 1vw;
  text-align: center;
}

.date-container .text {
  font-family: 'Grandis_Light';
  font-size: 1.5vw;
  text-align: center;
  text-transform: uppercase;
}

.title-container {
  flex: 1;
}

.title-container span {
  margin-right: 10px;
}

.title-container .text {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  text-align: center;
  display: inline-block;
}

.title-container .big-text {
  font-size: 5vw;
  text-transform: uppercase;
}

.box-color {
  background: rgba(255, 255, 255, 0.3);
  max-height: 9px;
  max-width: 9px;
  width: 9px;
  height: 9px;
  margin-right: 10px;
}

.filter-input-container-bottom {
  align-items: center;
  border-bottom: 2px solid black;
  display: flex;
  padding: 0 2vw;
  padding-right: 0;
}

.filter-input-container-bottom-mobile {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.filter-input-container-bottom-mobile form {
  width: 100%;
}

.filter-input-container-bottom-mobile div {
  align-items: center;
  border-right: 2px solid black;
  font-size: 5.5vw;
  display: flex;
  height: 100%;
  justify-content: center;
}

.filter-box-mobile {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.button-mode-container-mobile {
  border-right: 2px black solid;
}

.button-mode-container-mobile .row {
  display: flex;
  height: 100%;
}

.button-mode-container-mobile .row div {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50%;
}

.button-mode-container-mobile span {
  font-size: 5.5vw;
}

.button-mode-container-mobile .row:nth-child(odd) {
  border-bottom: solid 2px black;
}

.button-mode-container-mobile .row:nth-child(even) {
  border-bottom: solid 2px black;
}

.filter-input-container-bottom form {
  align-items: center;
  display: flex;
  width: 100%;
}

.no-right {
  border-right: none;
}

.filter-fix {
  border: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5vw;
}

.filter-fix p {
  font-family: 'Grandis_Medium';
}

.view-mode-box {
  display: flex;
}

.view-mode-box-container {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  font-size: 1.5vw;
  display: flex;
}

.view-mode-box-container div {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 50%;
}

/* estilos */

.unstyled li::before {
  display: none;
}

.styled-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: rgba(40, 40, 40, 0.0);
  color: black;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: solid 2px black;
  margin-right: 10px;
}

.filters-container-main {
  padding: 1vw 2vw;
}

.li-text {
  font-family: 'Roboto-Regular';
  font-size: 0.9vw;
}

.filter-item {
  cursor: pointer;
}

.checkbox {
  border: solid 2px black;
  height: 0.8vw;
  margin-right: 1vw;
  width: 0.8vw;
}

.unstyled {
  padding-left: none;
  margin-bottom: 2vw;
}

.unstyled li {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.filters-button-main {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1vw 2vw;
  justify-content: center;
}

.filters-button-main p {
  font-family: 'Grandis_Medium';
  font-size: 0.9vw;
  padding: 0.5vw 2vw;
  border: solid 2px black;
  text-transform: uppercase;
}

.info-container {
  font-family: 'Grandis_Medium';
  font-size: 0.9vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid black;
}

.applied-filters-box {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 4vw;
  justify-content: space-between;
  width: 100%;
  border-right: 2px solid black;
}

.applied-filters-box-mobile {
  display: flex;
  flex-wrap: wrap;
  padding: 4vw;
  width: 100%;
}

.applied-filters-container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-width: 70%;
}

.applied-filters-box span {
  font-size: 0.7vw;
  margin: 0 10px;
}

/* .applied-filters-box span:first-child {
  margin-left:0;
} */

.no-results h2 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
}

.no-results p {
  font-family: 'Grandis_Light';
  font-size: 1vw;
}

.list-mode {
  padding: 1vw 4vw;
}

.list-mode .title-container {
  margin: 0.3vw 0;
}

.list-mode .title-container .text {
  font-size: 1.3vw;
}

.list-mode .title-container .big-text {
  font-size: 1.8vw;
}

.list-mode .programs-container .text,
.list-mode .programs-container .text-tag {
  font-size: 0.9vw;
}

.list-mode .date-container .text {
  font-size: 1vw;
}

.text-light {
  font-family: 'Grandis_Light';
}

.view-sort-box-container {
  position: relative;
}

.view-sort-button-container {
  align-items: center;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.view-sort-dropdown-container {
  position: absolute;
  left: -4px;
  right: -2px;
  border: solid black 2px;
  border-top: none;
  overflow: hidden;
  transition: all 0.8s ease;
}

.view-sort-dropdown-container.inactive,
.inactive.view-sort-dropdown-container .column div {
  border: none;
  transition: all 0.5s ease;
}

.view-sort-dropdown-container .row {
  display: flex;
  flex-direction: row;
}

.view-sort-dropdown-container .column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.view-sort-dropdown-container .column div {
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
  border: solid 1px black
}

.view-sort-box-container span {
  font-size: 1.4vw;
  display: inline-block;
}

.view-sort-box-container .arrow {
  font-size: 1.2vw;
  margin-left: 0.5vw;
  transform: rotate(90deg);
  transition: all 0.5s ease;
}

.view-sort-box-container .active {
  transform: rotate(270deg);
}


.search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  font-family: 'Roboto-Light';
  font-size: 1vw;
  color: rgba(255, 255, 255, 0.25);
  opacity: 1;
  /* Firefox */
}

.search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.25);

  font-family: 'Roboto-Light';
  font-size: 1vw;
}

.search-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.25);

  font-family: 'Roboto-Light';
  font-size: 1vw;
}


.paginator {
  align-items: center;
  display: flex;
  font-family: 'Roboto-Light';
  font-size: 0.8vw;
  flex-direction: row;
  justify-content: center;
  height: 8vh;
  min-height: 150px;
}

.paginator-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
}

.paginator span {
  display: inline-block;
  font-size: 1.3vw;
}

.paginator .left {
  transform: rotate(180deg);
}

.paginator p {
  cursor: pointer;
  transition: all 0.5s ease;
}

.paginator p:hover {
  color: #ebec8a;
  transform: scale(1.5);
}

.paginator h4 {
  font-family: 'Roboto-Bold';
}

.paginator .current-page {
  align-items: center;
  border: solid 2px black;
  display: flex;
  justify-content: center;
  width: 1.5vw;
  height: 1.5vw;
}

.programs-container .text-tag {
  background: #8ba9e5;
  padding: 0.2vw 0.5vw;
  font-size: 0.8vw !important;
}

.programs-container .text {
  font-family: 'Roboto-Regular';
  font-size: 0.8vw;
}

.container-option-mobile {
  position: relative;
}

.option-selector {
  border: solid 2px black;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.option-selector div {
  flex: 1;
  min-width: 100%;
}

.container-option {
  align-items: flex-start !important;
  display: flex;
  justify-content: flex-start !important;
  position: absolute;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .programs-container .text-tag {
    padding: 0.2vw 0.5vw;
  }

  .paginator {
    font-size: 1.2vw;
  }

  .paginator span {
    display: inline-block;
    font-size: 1.8vw;
  }

  .paginator .current-page {
    align-items: center;
    border: solid 2px black;
    display: flex;
    justify-content: center;
    width: 1.5vw;
    height: 1.5vw;
  }

  .paginator .current-page {
    width: 2vw;
    height: 2vw;
  }

  .search-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Roboto-Light';
    font-size: 1.5vw;
    color: rgba(255, 255, 255, 0.25);
    opacity: 1;
    /* Firefox */
  }

  .search-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.25);
    font-family: 'Roboto-Light';
    font-size: 1.5vw;
  }

  li {
    font-size: 1.6vw;
    line-height: 1.6vw;
  }

  .search-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.25);
    font-family: 'Roboto-Light';
    font-size: 1.5vw;
  }

  .filter-input-container-bottom {
    font-size: 2vw;
  }

  .filters-container-main {
    margin-top: 1vw;
  }

  .filter-selector p {
    font-size: 1.5vw;
  }

  .li-text {
    font-size: 1.2vw;
    line-height: 1.2vw;
  }

  .checkbox {
    border: solid 1px black;
    height: 10px;
    margin-right: 1vw;
    width: 10px;
  }

  .info-container {
    font-size: 1.3vw;
  }

  .applied-filters-box span {
    font-size: 0.9vw;
    margin: 0 10px;
  }

  .filters-button-main p {
    font-size: 1.2vw;
    padding: 0.5vw 2vw;
  }

  .view-sort-box-container span {
    font-size: 1.9vw;
  }

  .view-mode-box-container {
    font-size: 1.9vw;
  }

  .programs-container .text,
  .programs-container .text-tag {
    font-size: 1.4vw;
  }

  .title-container .big-text {
    font-size: 6vw;
  }

  .option-data-column {
    font-size: 2.8vw;
  }

  .list-mode {
    padding: 2vw 4vw;
  }

  .list-mode .title-container {
    margin: 0.5vw 0;
  }

  .list-mode .title-container .text {
    font-size: 1.7vw;
  }

  .list-mode .title-container .big-text {
    font-size: 2vw;
  }

  .list-mode .programs-container .text,
  .list-mode .programs-container .text-tag {
    font-size: 0.9vw;
  }

  .list-mode .date-container .text {
    font-size: 1.2vw;
  }

  .list-mode .box-color {
    margin-right: 7px;
  }

  .list-mode .option-data-row {
    font-size: 2.6vw;
    width: 13%;
  }

  #filter-screen .filter-item-container {
    font-size: 1.3vw;
  }

  #filter-screen .filter-buttons {
    font-size: 1.3vw;
  }

  #filter-screen .filter-text {
    font-size: 1.3vw;
  }

  #filter-screen .suggestion-content {
    font-size: 1.3vw;
  }
}

@media (max-width: 768px) {

  .container-data-image .object-date {
    display: none;
  }

  .paginator {
    font-size: 4.5vw;
    min-height: 140px;
  }

  .paginator span {
    display: inline-block;
    font-size: 7.8vw;
  }

  .paginator .current-page {
    width: 6.5vw;
    height: 6.5vw;
  }

  .paginator-content {
    width: 80%;
  }

  .list-mode {
    padding: 4vw 6vw;
  }

  .list-mode .title-container {
    margin: 0.5vw 0;
  }

  .list-mode .title-container .text {
    font-size: 5.3vw;
  }

  .list-mode .title-container .big-text {
    font-size: 10vw;
    line-height: 11vw;
  }

  .list-mode .programs-container .text,
  .list-mode .programs-container .text-tag {
    font-size: 3.5vw;
  }

  .list-mode .date-container .text {
    font-size: 5vw;
  }

  .list-mode .box-color {
    margin-right: 7px;
  }

  .list-mode .option-data-row {
    font-size: 2.6vw;
    width: 13%;
  }

  #filter-screen .container {
    height: auto;
  }

  #filter-screen .filters-block {

    margin: 2vh;
  }

  #filter-screen .filter-input-container form {
    font-size: 3.5vw;
    padding: 2vw 4vw;
  }

  #filter-screen .filter-input-container span {
    font-size: 3.5vw;
  }

  #filter-screen input {
    font-family: 'Grandis_Medium';
    font-size: 3vw;
  }

  #filter-screen .filter-item-container {
    font-size: 3vw;
  }

  #filter-screen .filter-item-tag {
    padding: 0 4vw;
  }

  #filter-screen .filter-item-tag-container p {
    padding: 2vw 4vw;
  }

  #filter-screen .filter-button-container {
    font-size: 3vw;
  }

  #filter-screen .filter-text {
    align-items: flex-end;
    display: flex;
    padding: 2vw;
  }

  #filter-screen .filter-text {
    width: 100%;
  }

  #filter-screen .filter-buttons {
    margin-top: 3vh;
  }

  #filter-screen .filter-buttons p {
    border: solid 2px black;
    margin-left: 1vw;
    padding: 2vw 3vw;
  }

  #filter-screen .suggestion-box {
    margin-top: 2vh;
  }

  #filter-screen .suggestion-content {
    padding: 1vw;
    font-family: 'Grandis_Medium';
    font-size: 3vw;
  }

  .info-container {
    font-family: 'Grandis_Medium';
    font-size: 3vw;
  }

  .all-filter {
    min-width: 30%;
  }

  .delete-applied-filters span {
    margin: 0 20px;
  }

  .programs-container .text,
  .programs-container .text-tag {
    font-size: 2.7vw;
  }

  .title-container .text {
    font-size: 3.5vw;
  }

  .title-container .big-text {
    font-size: 7vw;
    display: block;
    line-height: 6vw;
    text-align: left;
    margin-top: 3vw;
  }

  .date-container .text {
    font-size: 3.5vw;
  }

  .date-container {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }

  .option-data-row-mobile {
    font-size: 7vw;
  }

  .option-data-row-mobile span {
    margin-left: 7.5vw;
  }

  .option-data-column,
  .option-data-row {
    display: none;
  }

  .container-data {
    width: 50%;
  }

  .programs-container {
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
  }

  #filter-screen .filter-buttons {
    font-size: 2vw;
  }

  .search-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Roboto-Light';
    font-size: 4vw;
    color: rgba(255, 255, 255, 0.25);
    opacity: 1;
    /* Firefox */
  }

  .search-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.25);
    font-family: 'Roboto-Light';
    font-size: 1.5vw;
  }

  #filter-screen .filter-input-container span {
    font-size: 4vw;
  }

  #filter-screen .filter-item-container {
    font-size: 4vw;
  }

  #filter-screen .filter-buttons {
    font-size: 4vw;
  }

  #filter-screen .filter-text {
    font-size: 4vw !important;
    margin-bottom: -2vw;
  }

  #filter-screen .filter-text p {
    margin-bottom: -2vw;
    margin-left: -2vw;
  }

  .filter-selector p {
    font-size: 5vw;
    text-transform: uppercase;
    margin-bottom: 1vw;
  }

  .filters-container-main {
    padding: 6vw 8vw !important;
  }

  .filters-button-main p {
    font-size: 5vw;
    padding: 3vw 8vw;
    border: solid 2px black;
  }

  .li-text {
    font-family: 'Roboto-Regular';
    font-size: 4.5vw;
    margin-bottom: 3vw;
  }

  .checkbox {
    border: solid 1px black;
    height: 4vw;
    margin-right: 3vw;
    width: 4vw;
  }

  .title-container span {
    margin-right: 3px;
  }

  .title-container .big-text-fix {
    font-size: 6vw;
    display: block;
    line-height: 6vw;
    text-align: left;
    margin-top: 3vw;
  }

  #filter-screen input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1;
    /* Firefox */
    font-size: 4vw !important;
  }

  #filter-screen input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black;
    font-size: 4vw !important;
  }

  .filter-selector span {
    font-size: 4.5vw;
  }

  .programs-container .text-tag {
    font-size: 2.7vw !important;
  }

  .suggestion-item {
    font-size: 4vw;
    line-height: 6vw;
  }
}




/*
.programs-container .text {
    font-family: 'Grandis_Medium';
    font-size: 0.9vw;
    text-align: center;
    text-transform: uppercase;
}

.social-networks {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    width: 50%;
}

*/