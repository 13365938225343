/* Grid */
#pes .column {
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

#pes .border-right {
  border-right: 1px solid black;
}

#pes .column-display {
  display: flex;
  flex-direction: column;
}

#pes .row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  width: 100%;
}

#pes .goal-border {
  border: solid black 1px;
}

#pes .item {
  flex-grow: 1;
}

#pes .item-info {
  height: 100%;
  width: 100%;
}

#pes .item-button {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-left: 1.5vw; */
  text-decoration: none;
}

#pes .item-top {
  border-bottom: 1px solid black;
}

#pes .item-center {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

#pes .item-bottom {
  border-top: 1px solid black;
}

#pes .item-left {
  border-right: 1px solid black;
}

#pes .item-center-row {
  border-right: 1px solid black;
  border-left: 1px solid black;
}

#pes .item-right {
  border-left: 1px solid black;
}

#pes .no-left {
  border-left: none;
}

#pes .no-right {
  border-right: none;
}

#pes .no-top {
  border-top: none;
}

#pes .no-bottom {
  border-bottom: none;
}

#pes .background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}

#pes .container {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
}

#pes .hero {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

#pes .hero .hero-info {
  align-items: center;
  display: flex;
  bottom: 0;
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  line-height: 3.1vw;
  justify-content: flex-end;
  left: 0;
  position: absolute;
}

#pes .hero .hero-info div {
  padding: 4vw;
  text-transform: uppercase;
}

#pes .goal-container {
  padding: 2vw;
}

#pes .goal-container h3 {
  font-family: 'Grandis_Medium';
  font-size: 2.5vw;
}

#pes .goal-container p {
  font-size: 1vw;
  line-height: 1.4vw;
  font-family: 'Roboto-Light';
  font-weight: 600;
}

#pes .goal-container {
  padding: 2vw;
}

#pes .info-text {
  display: flex;
  height: 100%;
  padding: 6vw;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

#pes .info-text h3 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  margin-bottom: 1.3vw;
  text-transform: uppercase;
}

#pes .info-text p {
  font-size: 1.2vw;
  line-height: 1.8vw;
  font-family: 'Roboto-Light';
  font-weight: 600;
}

#pes .info-container {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
  width: 100%;
}

#pes .announcement {
  height: 100%;
  width: 100%;
}

#pes .font-button {
  font-family: 'Grandis_Light';
  font-weight: 100;
}

#pes .switcher-button {
  align-items: center;
  font-family: 'Grandis_Medium';
  display: flex;
  cursor: pointer;
  font-size: 1.1vw;
  position: absolute;
  text-transform: uppercase;
  border: 2px solid black;
  padding: 1vw 2vw;
  right: 4vw;
  bottom: 4vw;
}

.more-button {
  align-items: center;
  font-family: 'Grandis_Medium';
  display: flex;
  cursor: pointer;
  font-size: 0.8vw;
  text-transform: uppercase;
  margin-top: 1vw;
}

.more-button span {
  margin-left: 1vw;
}

#pes .switcher-button span {
  margin-right: 1vw;
}

#pes .grid-announcement-image {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-bottom: 2px solid black;
  display: flex;
  flex-grow: 2;
}

#pes .grid-announcement-block {
  border-top: 2px solid black;
  display: flex;
  flex-grow: 1;
}

#pes .container-announcement {
  flex-direction: column;
  overflow: hidden;
}

#pes .grid-announcement-block {
  border-top: 2px solid black;
}

#pes .announcement-info-container {
  bottom: 0;
  height: 100px;
  left: 0;
  padding: 5vw;
  position: absolute;
  width: 100px;
  overflow: auto;
}

#pes .announcement-info-container h2 {
  font-size: 1.2vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
}

#pes .announcement-info-container p,
#pes .announcement-info-container li {
  font-size: 1.2vw;
  line-height: 1.8vw;
  font-family: 'Roboto-Light';
  font-weight: 600;
}

#pes .announcement-info-container ul {
  margin: 0;
  padding: 0;
}

#pes .announcement-title h2 {
  font-size: 3vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
}

#pes .announcement-title .header-title {
  font-family: 'Grandis_Medium';
  font-size: 4.8vw;
  font-weight: 100;
}

#pes .announcement-date h3 {
  font-size: 1.3vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
  text-align: center;
}

#pes .announcement-date p {
  font-size: 1.1vw;
  font-family: 'Roboto-Regular';
  font-weight: 100;
}

#pes .FAQ-container {
  font-family: 'Grandis_Medium';
  display: flex;
  padding: 4vw;
  overflow: auto;
}

#pes .FAQ-container .FAQ-container-box {
  margin: 0 auto;
  width: 80%;
}

#pes .ask-box {
  display: flex;
  cursor: pointer;
  font-size: 1.2vw;
  justify-content: space-between;
  padding: 10px;
}

#pes .ask-box p {
  margin-right: 55px;
}

#pes .ask-container {
  transition: 0.8s;
  -webkit-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
}

#pes .ask-container .answer {
  padding-left: 10px;
  padding-right: 1.4vw;
  font-family: 'Roboto-Light';
  line-height: 3vh;
}

#pes .inactive {
  display: none
}

#pes .active {}

#pes .static {
  display: block;
}

.question-arrow-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.question-arrow-container span {
  cursor: pointer;
  display: inline-block;
}

.question-arrow-container .arrow-left {
  transform: rotate(180deg);
  margin-right: 1vw;
}

.question-arrow-container .arrow-left:hover {
  transform: rotate(180deg) scale(1.6);
  margin-right: 1vw;
}

.question-title:hover {
  color: #EBEC8B;
}

.action-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.5vw;
}

.row-items {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.item-button-width {
  max-width: 20%;
  width: 20%;
}

.item-button-width-end {
  max-width: 16.666%;
  width: 16.666%;
}

.item-button-border {
  border: solid 1px black;
}

.content-block {
  display: flex;
  flex-wrap: wrap;
  overflow: auto !important;
  align-content: flex-start;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
}

.year-selector {
  position: relative;
  width: 50%;
  font-family: 'Grandis_Medium';
  font-size: 1vw;
}

.year-options {
  border: solid 1px black;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1000;
}

.year-options p {
  padding: 1vw 0 1vw 0;
}

.year-options p:hover {
  background: #EBEC8B;
}

.year-selector-data {
  border: solid 2px black;
  display: flex;
  flex-direction: row;
  padding: 1vw 1vw;
  justify-content: space-between;
}

.year-selector-data span {
  display: inline-block;
  transform: rotate(90deg);
}

.year-selector-data .active {
  transform: rotate(270deg);
}

#pes .announcement-info-container strong {
  font-family: 'Roboto-Regular';
}


@media (min-width: 768px) and (max-width: 1024px) {
  .header-participants .header-title {
    font-size: 3vw;
  }

  .year-selector {
    font-size: 1.9vw;
  }

  .year-selector-data {
    padding: 1.5vw 2vw;
  }

  .item-button-width {
    max-width: 33.34%;
    width: 33.34%;
  }

  .item-button-width-end {
    max-width: 25%;
    width: 25%;
  }

  #pes .info-text p {
    font-size: 1.6vw;
    margin-bottom: 1.8vw;
    line-height: 2.3vw;
  }

  #pes .info-text h3 {
    font-family: 'Grandis_Medium';
    font-size: 3.5vw;
    margin-bottom: 1.3vw;
    text-transform: uppercase;
  }

  #pes .goal-container p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #pes .goal-container h3 {
    font-family: 'Grandis_Medium';
    font-size: 3.2vw;
  }

  #pes .ask-box {
    font-size: 1.8vw;
  }

  .more-button {
    align-items: center;
    font-family: 'Grandis_Medium';
    display: flex;
    cursor: pointer;
    font-size: 1.4vw;
    text-transform: uppercase;
  }

  #pes .switcher-button {
    font-size: 2vw;
    margin-bottom: 2vw;
  }

  #pes .announcement-info-container p {
    font-size: 1.6vw;
    line-height: 2.3vw;
    font-family: 'Roboto-Light';
    font-weight: 600;
    margin-bottom: 1vw;
    overflow: auto;
  }

  #pes .announcement-date h3 {
    font-size: 2.1vw;
    font-family: 'Grandis_Medium';
    font-weight: 100;
    max-width: 80%;
    text-align: center;
  }

  #pes .announcement-date p {
    font-size: 1.8vw;
    font-family: 'Roboto-Regular';
    font-weight: 100;
  }

  #pes .hero .hero-info div {
    padding: 6vw;
    font-size: 3vw;
    line-height: 4.8vw;
  }
}

@media (max-width: 768px) {

  .year-selector {
    font-size: 3.8vw;
  }

  .year-selector-data {
    padding: 3vw 4vw;
  }

  .item-button-width-end {
    max-width: 50%;
    width: 50%;
  }


  #pes .hero .hero-info div {
    padding: 8vw;
    font-size: 6vw;
    line-height: 7.8vw;
  }

  #pes .info-text h3 {
    font-family: 'Grandis_Medium';
    font-size: 6vw;
    margin-bottom: 2.3vw;
    text-transform: uppercase;
    font-weight: 100;
  }

  #pes .info-text p {
    font-size: 3.5vw;
    line-height: 4.8vw;
    margin-bottom: 1vw;
    font-family: 'Roboto-Light';
    font-weight: 600;
  }

  #pes .info-text {
    padding: 10vw;
  }

  .more-button {
    font-size: 3vw;
    margin-top: 5vw;
  }

  .item-action-button span {
    font-size: 5.6vw;
  }

  #pes .FAQ-container {
    padding: 9vw;
  }

  #pes .ask-box {
    font-size: 3.3vw;
  }

  #pes .switcher-button {
    font-size: 3vw;
    margin-bottom: 15vw;
  }

  #pes .goal-container h3 {
    font-size: 5vw;
  }

  #pes .goal-container p {
    font-weight: 300;
    line-height: 4.4vw;
    font-size: 4vw;
  }

  #pes .hero .hero-info {
    font-size: 5vw;
    line-height: 7.8vw;
  }

  #pes .info-text h3 {
    font-size: 6vw;
    margin-bottom: 2.3vw;
  }

  #pes .info-text p {
    font-size: 3.5vw;
    line-height: 4.8vw;
    margin-bottom: 1vw;
  }

  #pes .ask-box {
    font-size: 3.3vw;
  }
}