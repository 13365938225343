#object {}

#object a {
  text-decoration: underline;
  font-weight: bold;
  font-family: 'Grandis_Medium';
}

#border-box {
  display: flex;
  flex-direction: row;
  border: solid 2px black;
}

#content-container {
  border-right: solid 2px black;
}

#infobox-container,
#content-container {
  min-height: 100vh;
  min-width: 50%;
  width: 50%;
}

#border-box-mobile {
  display: flex;
  flex-direction: column;
}

#border-box-mobile #infobox-container,
#border-box-mobile #content-container {
  min-width: 100%;
  width: 100%;
}

.pdf-visor {
  bottom: 0;
  position: absolute;
  left: 0;
  height: 100vh;
  right: 0;
  top: 0;
}

.viewer-embed {
  height: 100%;
  width: 100%;
}

.pdf-button-close {
  align-items: flex-end;
  display: flex;
  padding: 0.5vw;
  justify-content: flex-end;
}

.pdf-button-close {
  font-size: 2.5vw;
  cursor: pointer;
}

#object .container {
  display: flex;
  flex-direction: column;
}

#object span {
  display: inline-block;
}

#object .header {
  border-bottom: solid 2px black;
  display: flex;
  flex-direction: row;
}

#object .header h2 {
  font-family: 'Grandis_Medium';
  font-size: 3vw;
  text-align: center;
  text-transform: uppercase;
}

#object .header h3 {
  font-family: 'Grandis_Medium';
}


#object .left-arrow {
  transform: rotate(180deg);
}

#object .border-left {
  border-left: solid 2px black;
}

#object .button-container {
  align-items: center;
  display: flex;
  font-size: 2.5vw;
  justify-content: space-between;
  width: 100%;
}

#object .button-container h3 {
  flex: 1;
}

#object .button-container span {
  cursor: pointer;
}

#object .button-container span:nth-child(even) {
  margin: 1vw;
}

#object .button-container h3 {
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.5s all ease;
}

#object .button-container h3:hover {
  color: #ebec8a;
}

#object .border-right {
  border-right: solid 2px black;
}

#object .controls {
  align-items: flex-start;
  border-top: solid 2px black;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 2vw 2vw;
  min-height: 15vh;
}

#object .controls-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#object .controls-buttons span {
  cursor: pointer;
  font-size: 1.5vw;
  margin-right: 1vw;
}

#object .controls-social span {
  font-size: 1.4vw;
  margin-left: 0.8vw;
}

#object .infobox-container {
  display: flex;
  padding: 4vw;
}

#object .infobox-container h2 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  line-height: 2.5vw;
}

#object .infobox-container h3 {
  font-family: 'Grandis_Medium';
  font-size: 1.2vw;
  line-height: 1.3vw;
}

#object .content-data {
  margin-top: 2vw;
  height: 100%;
  overflow: auto;
}

#object .infobox-container {
  font-family: 'Roboto-Light';
  font-size: 1.2vw;
  line-height: 1.8vw;
}

#object .pdf-button {
  border: solid 2px black;
  cursor: pointer;
  margin-left: 1vw;
  padding: 0.7vw 1vw;
  font-family: 'Grandis_Medium';
}


#object .content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  max-width: 70%;
  flex-grow: 1;
}



#object .background {
  align-items: flex-end;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  font-size: 1.2vw;
  font-family: 'Roboto-Regular';
  color: rgba(255, 255, 255, 0.7);
  height: 100%;
  justify-content: flex-end;
  width: 100%;
}

#object .img-padding {
  padding: 1vw;
}

#object .action-buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  font-size: 1.5vw;
  justify-content: space-between;
  padding-left: 1vw;
  width: 40%;
}


#object .tags p {
  font-family: 'Roboto-Regular';
  font-size: 1vw;
  line-height: 1.3vw;
}

#object .tags {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#object .tags h3 {
  margin-bottom: 1vw;
}

.clickeable p {
  cursor: pointer;
}

.clickeable p a:hover {
  color: #ebec8a;
}

.small-title h2 {
  font-size: 2vw !important;
}

.object-header {
  padding: 1vw;
}

#object .infobox-container strong {
  font-family: 'Roboto-Regular';
}

.person-tags h3 {
  font-size: 0.8vw;
  line-height: 1.2vw;
}

.person-tags p {
  font-family: 'Grandis_Medium';
  font-size: 0.7vw;
  line-height: 1vw;
}

@media (min-width: 768px) and (max-width: 1024px) {

  .person-tags h3 {
    font-size: 1.7vw !important;
  }

  .person-tags p {
    font-family: 'Grandis_Medium';
    font-size: 1.3vw;
    line-height: 1.5vw;
  }

  #object .infobox-container {
    font-size: 1.6vw;
    margin-bottom: 1.8vw;
    line-height: 2.3vw;
  }

  #object .infobox-container h2 {
    font-size: 2.5vw;
    line-height: 3vw;
  }

  #object .controls-buttons span {
    font-size: 3vw;
    margin-right: 4vw;
  }

  #object .infobox-container h3 {
    font-size: 2vw;
    line-height: 2.3vw;
  }

  #object .tags p {
    font-size: 1.5vw;
    line-height: 2vw;
  }

  #object .background {
    font-size: 1.6vw;
  }
}


@media (max-width: 768px) {

  .content-headers * {
    font-family: 'Roboto-Light';
    font-size: 3.5vw !important;
    line-height: 4.8vw !important;
  }

  #content-container .background .background-label {
    display: none;
  }

  .controls-container {
    margin-top: 1rem;
  }

  #object .background {
    font-size: 3.5vw;
  }

  #object .tags {
    height: auto;
    overflow: auto;
  }

  .pdf-button-close {
    font-size: 6vw;
    cursor: pointer;
  }

  .pdf-button-close {
    padding: 4vw;
  }


  #object .pdf-button {
    border: solid 2px black;
    font-size: 4vw;
    margin-left: 1vw;
    padding: 2vw 3vw;
  }

  #object .mobile-fav {
    font-size: 4vw;
  }

  #object .controls {
    padding: 0 6vw;
  }

  #object .controls-buttons span {
    font-size: 8vw;
    margin-right: 5vw;
  }

  #object .infobox-container {
    font-family: 'Roboto-Light';
    font-size: 3.5vw;
    line-height: 4.8vw;
  }

  #object .infobox-container h2 {
    font-family: 'Grandis_Medium';
    font-size: 6vw;
    line-height: 7vw;
    margin-bottom: 4vw;
  }

  #object .infobox-container h3 {
    font-size: 5vw;
    line-height: 5.3vw;
    margin-bottom: 2vw;
    margin-top: 3VW;
  }

  #object .tags {
    display: inline-block;
    margin-top: 2vw;
  }

  #object .tags p {
    font-size: 3.5vw;
    line-height: 5.3vw;
  }

  #object .infobox-container {
    justify-content: center;
    padding-top: 8vw;
  }

  #object .content-container {
    width: 90%;
  }

  #content-container {
    border-bottom: 2px black solid;
    min-height: auto !important;
  }
}

@media (min-width: 768px) {

  .button-container {
    position: fixed;
    width: 10% !important;
  }

  #content-container {
    overflow: hidden;
  }

  #object {
    max-height: 100vh;
    overflow: hidden;
  }

  #infobox-container {
    position: relative;
    max-height: 100vh;
    overflow: auto;
  }

  #object .action-buttons {
    position: relative;
  }

  #object .tags.clickeable {
    display: inline-block;
    bottom: 5vh;
    position: fixed;
    height: 50vh;
    max-height: 50vh;
    width: 12%;
    overflow-y: scroll;
  }

  .background.img-padding {
    position: relative;
  }

  .background.img-padding .background-label {
    display: none;
  }

  #object .background-label {
    color: whitesmoke;
    font-size: 1.2vw;
    font-family: 'Roboto-Regular';
  }

  #object .content-headers {
    display: block;
    padding: 2vw 0;
  }
}