/* Grid */
#summer-about .column {
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
#summer-about .background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}
#summer-about .content-container {
  border-right: 1px solid black;
  border-bottom: 2px solid black;
  height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100px;
}
#summer-about .content-padding {
  padding: 0 5vw;
  margin-top: 5vw;
}

.content-padding-first {
  padding: 0 5vw;
  margin-top: 7vw;
}

#goals-pes, #weeks-summer, #tutors-pes, .active-tutor, #invited-tutors, #academic_training {
  margin-top: 2vw;
}
#goals-pes div {
  display: flex;
  flex-wrap: wrap;
}
#summer-about .objective-item {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1vw;
}
#summer-about .content-container p {
  font-size: 1.2vw;
  line-height: 1.8vw;
  font-family: 'Roboto-Light';
  font-weight: 600;
}
#summer-about .content-container h2 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  margin-bottom: 2vw;
}
#summer-about .content-container #info-pes {
  display: flex;
  flex-direction: row;
}
#summer-about .content-container .content-column{
  padding-right: 4vw;
  width: 50%;
}

ul { 
  list-style-type: none;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 1vw;
}

li {
  font-family: 'Roboto-Regular';
    font-size: 1.2vw;
}
#summer-about .border-right {
  border-right: 1px solid black;
}
#summer-about .column-display {
  display: flex;
  flex-direction: column;
}
#summer-about .row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  width: 100%;
}

#summer-about .row-border {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin-top: 3vw;
}

#summer-about .item {
  flex-grow: 1;
}

#summer-about .item-row {
  border-left: 1px solid black;
  border-right: 1px solid black;
  flex-grow: 1;
}

#summer-about .item-button {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}
#summer-about .item-top {
  border-bottom: 1px solid black;
}
#summer-about .item-center {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
#summer-about .item-bottom {
  border-top: 1px solid black;
}
#summer-about .item-left {
  border-right: 1px solid black;
}
#summer-about .item-center-row {
  border-right: 1px solid black;
  border-left: 1px solid black;
}
#summer-about .item-right {
  border-left: 1px solid black;
}
#summer-about .no-left {
  border-left: none;
}
#summer-about .no-right {
  border-right: none;
}
#summer-about .no-top {
  border-top: none !important;
  margin-top: 0 !important;
}
#summer-about .no-bottom {
  border-bottom: none;
}
#summer-about .tutor-action {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 1vw;
}
#summer-about .tutor-action h2 {
  font-family: 'Grandis_Medium';
  margin-right: 1vw;
  margin-bottom: 0;
  text-transform: uppercase; 
}

.testimonials-container h3 {
  font-family: 'Grandis_Medium';
  font-size: 1.5vw;
  margin-bottom: 1vw;
}

#summer-about  .arrow-btn {
  display: inline-block;
}
#summer-about  .arrow-btn:nth-child(odd) {
  margin-right: 1em;
  transform: rotate(180deg);
}
#summer-about  .arrow-btn:nth-child(even) {
  margin-left: 1em;
}
#summer-about .container {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
}

#summer-about .hero {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}
#summer-about .hero .hero-info {
  align-items: center;
  display: flex;
  bottom: 0;
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  justify-content: flex-end;
  left: 0;
  position: absolute;
}
#summer-about .hero .hero-info div {
  padding: 4vw;
}


#summer-about .info-text {
  height: 100%;
  padding: 4vw;
  width: 100%;
}
#summer-about .info-text h3 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  margin-bottom: 3vw;
}
#summer-about .info-text p {
  font-size: 1.2vw;
  line-height: 1.2vw;
  font-family: 'Roboto-Light';
  font-weight: 600;
}


#summer-about .info-container {
  display: flex;
  height: 100%;
  width: 100%;
}

#summer-about .announcement {
  height: 100%;
  width: 100%;
}


#summer-about .grid-announcement-image {
  border-bottom: 2px solid black;
  display: flex;
  flex-grow: 2;
}
#summer-about .grid-announcement-block {
  border-top: 2px solid black;
  display: flex;
  flex-grow: 1;
}

#summer-about .container-announcement {
  flex-direction: column;
  overflow: hidden;
}

#summer-about .grid-announcement-block {
  border-top: 2px solid black;
}
#summer-about .announcement-info-container {
  border-right: 2px solid black;
  bottom: 0;
  height: 100px;
  left: 0;
  position: absolute;
  width: 100px;
}

#summer-about .dropdown-container {
  border: solid 1px black;
  position: absolute;
  left: -1px;
  right: -1px;
  margin-top: 10px;
}

#summer-about .dropdown-button {
  border: solid 1px black;
  font-family: 'Grandis_Medium';
  font-size: 1.2vw;
  padding: 10px;
  position: relative;
}

#summer-about .dropdown-button p {
  display: inline-block;
}


#summer-about .dropdown-button span {
  margin-left: 10px;
}

#summer-about .dropdown-button .item-dropdown {
  padding: 10px;
}
#summer-about .dropdown-button .item-dropdown span {
  margin-left: 10px;
}
#summer-about .icon-dropdown {
  display: inline-block;
  transition: 0.5s all;
}
#summer-about .icon-active {
  transform: rotate(270deg);
}
#summer-about .icon-inactive {
  transform: rotate(90deg);
}

#summer-about .content-container strong {
  font-family: 'Roboto-Bold';
}

@media (min-width: 768px) and (max-width: 1024px) {
  #summer-about .content-container h2 {
    font-size: 3vw;
    margin-bottom: 2.5vw;
  }
  #summer-about .content-container p {
    font-size: 1.6vw;
    line-height: 2.5vw;
  }
  li {
    font-size: 1.6vw;
  }
  .testimonials-container h3 {
    font-size: 2.5vw;
  }
}

@media (max-width: 768px) {
  #summer-about .content-container h2 {
    font-size: 5vw;
    margin-bottom: 2.5vw;
  }
  #summer-about .content-container p {
    font-size: 3.5vw;
    line-height: 4.8vw;
  }
  li {
    font-size: 2.6vw;
  }
  .testimonials-container h3 {
    font-size: 2.5vw;
  }
  #summer-about .content-padding {
    padding: 0 12vw;
    margin-top: 5vw;
  }
  #summer-about .dropdown-button span {
    font-size: 3vw;
  }
  #summer-about .dropdown-button {
    margin-left: 2vw;
  }
  #summer-about .objective-item {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
  }
  #summer-about .row-border {
    margin-top: 12vw;
  }
}