#about .carousel {
  display: flex;
  height: 100%;
  width: 100%;
}

#about .slider-container {
  position: relative;
  height: 100%;
}

#about .slider-button-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

#about .slider-grid-container {
  display: flex;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;


  /*
  border: solid 1px black;
  border-right: 1px black solid;
  align-items: center;
  
  justify-content: center;
  */
}

#about .reports-button .cursor {
  display: block;
  position: relative;
}

#about .reports-button {
  position: relative;
}

#about .reports-button .cursor .title {
  display: flex;
  justify-content: space-between;
}

.reports-container {
  border: solid 2px black;
  /* bottom: -1.5vw; */
  display: inline-block;
  flex-direction: column;
  left: 0;
  overflow: auto;
  position: absolute;
  z-index: 100;
  width: 100%;
  text-align: center;
}

.reports-container a {
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  outline: 1px solid black;
}

.report-item {
  border-bottom: 2px solid #000;
  background-color: #ebec8b;
}

.report-item:last-child {
  border-bottom: 0px solid #000;
}

.report-item:hover {
  background-color: #b5b661;
}



#about .container {
  display: flex;
  height: 100%;
  width: 100%;
}

#about .slider-button-container div {
  display: flex;
  font-size: 3vw;
  justify-content: space-between;
  width: 70%;
}

#about .slider-button-container span {
  cursor: pointer;
  display: inline-block;
}

#about .left {
  transform: rotate(180deg);
}

#about .button {
  display: flex;
  flex-direction: column;
  font-family: 'Grandis_Medium';
  font-size: 1.4vw;
  font-weight: 700;
  justify-content: space-between;
  padding: 2vw;
}

#about .button h2 {
  font-size: 2vw;
  font-weight: 100;
}

#about .button div {
  display: flex;
  justify-content: space-between;
}

#about .button span {
  cursor: pointer;
  display: inline-block;
}

#about .bottom {
  transform: rotate(90deg);
}

#about .text-description {
  align-items: center;
  display: flex;
  font-size: 2.5vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
  justify-content: center;
}

#about .text-description div {
  width: 80%;
}

#about .image-background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  height: 100%;
}

#about .years-container {
  align-items: center;
  border-bottom: solid 1px black;
  border-top: solid 1px black;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  justify-content: space-between;
  padding: 2vw 0;
}

#about .years-container h2 {
  font-size: 6vw;
  font-weight: 100;
}

#about .years-container p {
  cursor: pointer;
}

#about .years-container span {
  cursor: pointer;
  display: inline-block;
}

#about .years-container .top {
  transform: rotate(270deg);
}

#about .years-container .left {
  transform: rotate(180deg);
}

#about .years-container .right {
  transform: rotate(0deg);
}

#about .years-container span {
  transform: rotate(90deg);
}

#about .main-year {
  justify-content: center;
}

#about .column {
  border-left: solid 1px black;
  border-right: solid 1px black;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#about .row {
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

#about .full-description {
  font-size: 1.2vw;
  font-family: 'Roboto-Light';
  font-weight: 100;
  line-height: 1.8vw;
  padding: 2vw 4vw;
}

#about .full-description h2 {
  font-size: 2.5vw;
  font-family: 'Grandis_Medium';
  font-weight: 100;
  line-height: 2.8vw;
  margin-bottom: 1vw;
}

#about .history-content {
  align-items: center;
  border: solid 1px black;
  bottom: 0;
  font-size: 1.2vw;
  line-height: 1.8vw;
  font-family: 'Roboto-Light';
  display: flex;
  position: absolute;
  top: 0;
  padding: 10%;
}

#about .council-block {
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  display: flex;
  justify-content: center;
  padding: 4vw;
}

#about .council-block h2 {
  font-size: 1.7vw;
  line-height: 2vw;
  font-family: 'Grandis_Medium';
  margin-bottom: 2vw;
}

#about .block-container {
  display: flex;
  justify-content: space-between;
}

#about .council-container {
  font-size: 1.7vw;
  line-height: 2vw;
  font-family: 'Grandis_Medium';
  overflow: auto;
}

#about .council-container h3 {
  font-size: 1.3vw;
  font-weight: 100;
}

#about .council-container {
  font-size: 1.3vw !important;
  line-height: 2vw;
}

#about .year-selector {
  border: solid 1px black;
  display: flex;
  justify-content: center;
  padding: 1vw;
  width: 35%;
}

#about .year-selector span {
  display: inline-block;
  transform: rotate(90deg);
}

#about .year-option-container {
  border: solid 1px black;
  border-top: none;
  left: 0;
  text-align: center;
  position: absolute;
  right: 0;
  width: 35%;
}

#about .council-container h3 {
  font-size: 1vw;
  font-weight: 100;
  margin-bottom: 1vw;
  line-height: 1.5vw;
}

#about .council-container .padding-text {
  margin-bottom: 3vw;
}

/*
#about .council-container {
  font-size: 1.7vw;
  font-family: 'Grandis_Medium';
  padding: 4vw;
  overflow: auto;
} 


*/

#about .column .item-box {
  flex: 1;
}

#about .column .item-box:nth-child(odd) {
  border-bottom: solid 1px black;
}

#about .column .item-box:nth-child(even) {
  border-top: solid 1px black;
}

#about .year-block {
  display: flex;
}


#about .no-left {
  border-left: none !important;
}

#about .no-right {
  border-right: none !important;
}

#about .no-bottom {
  border-bottom: none !important;
}

#about .no-top {
  border-top: none !important;
}

#about .history-box-container {
  display: flex;
  justify-content: space-between;
}

#about .history-box-content {
  border-bottom: 1px solid black;
  flex: 1;
  overflow: auto;
  padding: 4vw;
}

#about .history-box-content h2 {
  margin-bottom: 3vw;
}

#about .year-item {
  font-family: 'Grandis_Medium';
  font-size: 2.5vw;
}

#about .years-control {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 2vw;
}

#about .years-control p {
  cursor: pointer;
}

#about .years-control span {
  cursor: pointer;
}

#about .history-box-years-control {
  font-family: 'Grandis_Medium';
  font-size: 1.5vw;
}

#about .history-text,
#about .history-text li {
  font-family: 'Roboto-Light';
  font-size: 1.2vw;
  line-height: 1.8vw;
}

#about .council-buttons-container {
  display: flex;
  flex-direction: column;
}

#about .council-buttons-container .item-box {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

#about .margin-top {
  margin-top: 1vw;
}

#about .council-block h3 {
  font-family: 'Grandis_Light';
  margin-bottom: 1.5vw;
  text-transform: uppercase;
}

#about .full-description .logo-text {
  font-family: 'Grandis_Medium';
  line-height: 2.1vw;
}

#about .item-box-2 {
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  flex: 1;
}

.council-logos {}

.council-logos p {
  padding: 0 1vw;
}

.council-logos img {
  width: 100%;
}



@media (min-width: 768px) and (max-width: 1024px) {

  #about .text-description {
    font-size: 3vw;
  }

  #about .full-description {
    font-size: 2vw;
    line-height: 2.5vw;
    padding: 4vw 6vw;
  }

  #about .full-description h2 {
    font-size: 3vw;
    line-height: 3.5vw;
    margin-bottom: 2vw;
  }

  #about .button {
    border-top: solid 1px black;
    font-size: 1.8vw;
    padding: 3vw;
  }

  #about .button h2 {
    font-size: 3vw;
  }

  #about .border-bottom {
    border-bottom: solid 1px black;
  }

  #about .history-content {
    font-size: 1.6vw;
    padding: 20%;
  }

  .header-title-middle {
    font-family: 'Grandis_Medium';
    font-size: 5.8vw;
    line-height: 5vw;
    text-align: left;
    padding: 0;
    margin-top: 4vw;
  }

  #about .history-box-years-control {
    font-family: 'Grandis_Medium';
    font-size: 2.5vw;
  }

  #about .history-text,
  #about .history-text li {
    font-family: 'Roboto-Light';
    font-size: 1.8vw;
    line-height: 2.6vw;
  }

  #about .year-item {
    font-size: 3vw;
  }
}

@media (max-width: 768px) {
  #about .year-selector {
    width: 70%;
  }

  #about .year-option-container {
    width: 70%;
  }

  #about .image-background {
    border-bottom: solid 1px black;
  }

  #about .slider-button-container-mobile {
    align-items: center;
    border-top: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;
    display: flex;
    justify-content: center;
  }

  #about .slider-button-container-mobile span {
    cursor: pointer;
    display: inline-block;
  }

  #about .slider-button-container-mobile div {
    display: flex;
    font-size: 8vw;
    justify-content: space-between;
    width: 90%;
  }

  #about .text-description {
    font-size: 5vw;
  }

  #about .full-description {
    font-size: 3.5vw;
    line-height: 5.1vw !important;
    padding: 4vw 8vw;
  }

  #about .full-description .logo-text {
    font-family: 'Grandis_Medium';
    line-height: 5.1vw !important;
  }

  #about .full-description strong {
    font-size: 3.5vw;
    line-height: 6vw;
  }

  #about .full-description h2 {
    font-size: 5vw;
    line-height: 5.8vw;
    margin-bottom: 4vw;
  }

  #about .item-box-mobile {
    flex: 1;
  }

  #about .button {
    border-left: solid 2px black;
  }

  #about .button {
    font-size: 3.4vw;
    padding: 5vw;
  }

  #about .button h2 {
    font-size: 5vw;
  }

  #about .years-container {
    flex: 1;
    flex-direction: row;
    font-size: 4vw;
    justify-content: space-between;
    padding: 2vw 1vw;
  }

  #about .year-block {
    justify-content: space-between;
  }

  #about .main-year {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  #about .history-content {
    font-size: 3.5vw;
    padding: 10%;
  }

  #about .margin-top {
    margin-top: 3vw;
  }

  #about .council-container {
    font-size: 3vw;
    padding: 6vw;
  }

  #about .council-container h3 {
    font-size: 2.5vw;
    font-weight: 100;
  }

  #about .year-selector {
    padding: 1vw 2vw;
  }

  #about .year-option-container {
    padding: 1vw 2vw;
  }

  #about .council-container .padding-text {
    margin-bottom: 5vw;
  }

  #about .history-text,
  #about .history-text li {
    font-size: 3.5vw;
    line-height: 3.8vw;
  }

  #about .history-box-content {
    padding: 8vw;
  }

  #about .history-box-years-control {
    font-size: 6vw;
  }

  #about .year-item {
    font-size: 4vw;
  }

  #about .years-control {
    padding: 0 10vw;
  }

  #about .council-block h2,
  #about .council-block p,
  #about .council-block h5 {
    font-size: 3.5vw;
    line-height: 4.8vw;
  }

  #about .council-block h3 {
    font-family: 'Grandis_Light';
    margin-bottom: 2vw;
    font-size: 3vw;
    line-height: 3.5vw;
    text-transform: uppercase;
  }

  #about .council-block h6 {
    font-size: 3.2vw;
    line-height: 3.5vw;
  }

  #about .council-block {
    padding: 10vw 4vw;
  }

  #about .council-logos p {
    width: 50%;
  }

  #about .council-logos img {
    width: 80%;
  }

  #about .column {
    flex: 1;
  }
}