* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
}

body {
  height: 100vh;
  width: 100%;
}

#main {
  position: relative;
}

.cursor {
  cursor: pointer;
}

/* Estilos del menú */
#menu {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  transition: 0.8s;
  -webkit-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
  top: 0;
  right: 0;
  z-index: 1000000;
}


.menu-active {
  width: 100%;
}

.menu-inactive {
  margin-right: -100vw;
}

.social-networks-mobile {
  display: none;
}

#sideMenu {
  border: solid 2px black;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  /* 100% Full-height */
  z-index: 1;
  /* Stay on top */
  bottom: 0;
  padding: 40px;
  overflow-y: scroll;
}

#menuContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#menuContainer a {
  color: black;
  text-decoration: none;
}

#menuItems {
  display: flex;
  flex-grow: 1;
}

#navigation {
  flex-grow: 1;
  font-family: 'Grandis_Medium';
  font-size: 5.2vw;
}

.login-menu {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: right;
  width: 15vw;
}

.login-menu p {
  cursor: pointer;
}

.search-bar-wrap {
  position: relative;
  overflow: hidden;
  height: 0;
  -webkit-transition: all 700ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 700ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 700ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 700ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */

  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */

}

.search-bar-container {
  border-bottom: 2px black solid;
}

.search-bar {
  position: relative;
  z-index: 10000;
}

.search-input {
  background: transparent;
  border: none;
  box-sizing: border-box;
  font-size: 1.2vw;
  height: 100%;
  padding: 12px 20px;
  width: 100%;
  outline: none;
  outline-width: 0;
}

.search-suggestions {
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 10;
  overflow: auto;
}

.suggestion-item {
  font-family: 'Grandis-Regular';
  font-size: 1.4vw;
  line-height: 2.5vw;
  cursor: pointer;
  margin: 10px;
  text-align: left;
  margin: 10px 10%;
  transition: all ease 0.5s;
}

.suggestion-item:hover {
  color: #ebec8a;
  ;
}

.login-menu a,
.login-menu p {
  display: block;
  font-family: 'Roboto-Regular';
}

.lectures,
.privacy-text {
  display: flex;
  font-size: 2.5vw;
  justify-content: space-between;
  margin-top: -15px;
  margin-bottom: 15px;
  width: 95%;
}

#sideOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.login-container-hover {
  bottom: 0;
  right: 0;
  position: absolute;
}

#sideOptions span {
  font-size: 2vw;
}

#sideOptions .user-btn span {
  font-size: 2.6vw;
}

#menuItems a {
  display: block;
}

#menuFooter {
  display: flex;
  font-family: 'Grandis_Medium';
  font-size: 1.2vw;
  justify-content: space-between;
  align-items: flex-end;
}

.footer-info-container {
  width: 90%;
}

.footer-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
}

.footer-info p {
  display: inline-block;
}

.lang-switcher {
  display: flex;
  justify-content: space-between;
  font-size: 1.1vw;
}

.lang-switcher .active {
  color: white;
}

.lang-switcher div {
  margin-left: 20px;
}


/* Estilos loader */
.loader {
  align-items: center;
  justify-content: center;
  font-family: 'Grandis_Medium';
  font-size: 0.7em;
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all ease 800ms;
}

.loader img {
  margin-bottom: 20px;
  width: 10%;
}

.loader.active {
  opacity: 1;
}


/* Estilos header */
.header-close {
  color: black;
  cursor: pointer;
  font-size: 1.8vw;
  text-decoration: none;
}

.image-cover-bg {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.image-cover {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
}

.centered-logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.social-networks {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 40%;
}

.social-networks a {
  color: black;
  text-decoration: none;
}

.social-networks-min {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 50%;
}

.header-item span {
  font-size: 1.8vw;
}

.header-title,
.animated-text:after {
  font-family: 'Grandis_Medium';
  font-size: 4.8vw;
  font-weight: 100;
  line-height: 5vw;
  text-transform: uppercase;
}

.animated-text:after {
  font-size: 3.1vw;
}

.header-subtitle {
  font-family: 'Grandis_Light';
  font-size: 3vw;
  font-weight: 100;
  line-height: 5vw;
  text-transform: uppercase;
}

.btn-menu span {
  font-size: 2.3vw;
}

.social-networks span {
  font-size: 1.2vw;
}

.logo-container {
  max-width: 40%;
}

.logo {
  height: auto;
  width: 100%;
  transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
}

.logo:hover {
  transform: scale(1.2);
}

.centered {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.header-item {
  border-bottom: 0px;
}

.register-button {
  align-items: flex-start;
  cursor: pointer;
  padding: 2.3vw;
}

.register-button h2 {
  font-family: 'Grandis_Medium';
  font-size: 2vw;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.register-button p {
  font-family: 'Grandis_Light';
  font-size: 1.1vw;
  text-transform: uppercase;
}

/*  Estilos grid */
.item-grid {
  height: 50%;
}

.item-button,
.search-btn,
.btn-menu,
.close-btn {
  cursor: pointer;
  padding: 2vw;
}

.item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-container img {
  border: none;
}

.item-container span {
  font-size: 2.2vw;
}

.item-action-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.item-action-button span {
  font-size: 1.6vw;
}

.item-button h2,
.item-button h4,
.item-button p {
  font-weight: 100;
}

.item-left-top {
  border-bottom: 1px black solid;
  border-right: 1px black solid;
}

.item-left-bottom {
  border-top: 1px black solid;
  border-right: 1px black solid;
}

.item-center-top {
  border-bottom: 1px black solid;
  border-left: 1px black solid;
  border-right: 1px black solid;
}

.item-center-bottom {
  border-left: 1px black solid;
  border-right: 1px black solid;
  border-top: 1px black solid;
}

.item-right-top {
  border-left: 1px black solid;
  border-bottom: 1px black solid;
}

.item-right-bottom {
  border-left: 1px black solid;
  border-top: 1px black solid;
}

.item-right-top-fixed {
  border-left: 1px black solid;
  border-bottom: 1px black solid;
}

.item-right-bottom-fixed {
  border-left: 1px black solid;
  border-top: 1px black solid;
}

.item-left-single {
  border-right: 1px black solid;
}

.item-right-single {
  border-left: 1px black solid;
}

.item-button h2 {
  font-family: 'Grandis_Medium';
  font-size: 1.5vw;
  text-transform: uppercase;
  word-break: break-word;
}

.item-button.guest_block h2 {
  font-size: 2vw;
}

.item-button h4 {
  font-family: 'Grandis_Light';
  font-size: 1.1vw;
  text-transform: uppercase;
}

.item-button p {
  font-family: 'HelveticaNeue-bold';
  font-size: 1vw;
}


/* Estilos footer */
.address-card-program {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 0 4vw;
}

.address-card-program h2 {
  font-family: 'Grandis_Medium';
  font-size: 3.5vw;
  font-weight: 100;
}

.address-card-program h3 {
  font-family: 'Grandis_Light';
  font-weight: 100;
  font-size: 2vw;
  margin-bottom: 10px;
}

.address-card-program p {
  font-family: 'Roboto-Regular';
  font-size: 1vw;
}

.address-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 0 2vw;
}

.address-card h2 {
  font-family: 'Grandis_Medium';
  font-size: 6.5vw;
}

.address-card p {
  font-family: 'Roboto-Regular';
  font-size: 1.2vw;
}

.contact {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.contact p {
  font-family: 'Grandis_Light';
  font-size: 1.2vw;
  margin-bottom: 10px;
}

.newsletter {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 2vw;
  width: 100%;
}

.newsletter h2 {
  font-family: 'Grandis_Medium';
  font-size: 1.5vw;
}

.newsletter p {
  font-family: 'Grandis_Light';
  font-size: 1vw;
}

.newsletter-input-container {
  margin-top: 1em;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.newsletter-input-container div {
  align-items: center;
  display: flex;
  margin-bottom: 1vw;
  width: 100%;
}

.newsletter-input-container div p {
  font-family: 'Grandis_Light';
  font-size: 1vw;
}


.newsletter-input-container span {
  font-size: 1vw;
  margin-left: 1.2em;
}

.newsletter-input {
  background: transparent;
  border: none;
  border-bottom: 2px solid black;
  box-sizing: border-box;
  font-family: 'Grandis_Light';
  font-size: vw;
  padding: 5px 0;
  width: 100%;
  outline: none;
  outline-width: 0;
}


/* Estilos Modal login */
.login-modal-container {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.login-modal {
  border: 1px solid black;
  display: flex;
  cursor: default;
  flex-direction: column;
  font-family: 'Grandis_Light';
  font-size: 1.3vw;
  justify-content: space-between;
  padding: 4vw;
  text-align: center;
}

.login-modal p {
  margin-bottom: 50px;
}

.login-modal div {
  display: flex;
  justify-content: space-between;
}

.login-modal div span {
  border: 2px solid black;
  font-family: 'Grandis_Medium';
  cursor: pointer;
  padding: 0.5vw 1vw;
  text-transform: uppercase;
  transition: all ease 0.5s;
}

.login-modal div span:hover {
  background: #ebec8a;
}

.close-btn span {
  transition: all ease 0.5s;
}

.close-btn span:hover {
  color: #ebec8a;
}

.color-yellow {
  color: #ebec8a;
}

.header-close span {
  transition: all 0.5s ease;
}

.header-close:hover span {
  color: #ebec8a;
  transform: scale(1.3);
}

li {
  font-family: 'Roboto-Light' !important;
  font-weight: 100 !important;
}

li::before {
  background: black;
  content: '';
  display: inline-block;
  margin-right: 0.8vw;
  height: 0.3vw;
  vertical-align: middle;
  width: 0.3vw;
}

/* Estilos varios */
::-webkit-scrollbar {
  display: none;
}


::placeholder,
.search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  font-family: 'Roboto-Light';
  font-size: 1.2vw;
  color: rgba(255, 255, 255, 0.25) !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder,
.search-input::placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.25);

  font-family: 'Roboto-Light';
  font-size: 1.2vw;
}

::-ms-input-placeholder,
.search-input::placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.25);

  font-family: 'Roboto-Light';
  font-size: 1.2vw;
}

.newsletter-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: 'Roboto-Regular';
  font-size: 1.1vw;
  color: rgba(255, 255, 255, 0.25);
}

.newsletter-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Roboto-Regular';
  font-size: 1.1vw;
  color: rgba(255, 255, 255, 0.25);
}

.newsletter-input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: 'Roboto-Regular';
  font-size: 1.1vw;
  color: rgba(255, 255, 255, 0.25);
}

.lang-fix {
  font-size: 2.3vw;
}

.search-container input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  font-size: 1.5vw;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header-close {
    font-size: 2.1vw;
  }

  .newsletter-input {
    font-size: 2vw;
  }

  .newsletter-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Roboto-Regular';
    font-size: 2vw;
  }

  .newsletter-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: 'Roboto-Regular';
    font-size: 2vw;
  }

  .register-button h2 {
    font-size: 3vw;
  }

  .newsletter-input::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: 'Roboto-Regular';
    font-size: 2vw;
  }

  .item-action-button {
    align-items: center;
    display: flex;
    margin-bottom: 1vw;
    justify-content: space-between;
  }

  .header-title,
  .animated-text:after {
    font-size: 4.8vw;
    padding: 4vw;
  }

  .header-fix,
  .animated-text:after {
    font-size: 3.8vw;
    padding: 0 2vw;
  }

  .small-header {
    font-size: 3vw;
  }

  .item-button h2 {
    font-size: 2.8vw;
  }

  .item-button.guest_block h2 {
    font-size: 2.5vw;
  }

  .item-button h4 {
    font-size: 2.3vw;
  }

  .item-button p {
    font-size: 1.9vw;
  }

  .item-action-button span {
    font-size: 2.6vw;
  }

  .contact p {
    font-family: 'Grandis_Light';
    font-size: 1.8vw;
    margin-bottom: 10px;
  }

  .newsletter p {
    font-family: 'Grandis_Light';
    font-size: 1.6vw;
  }

  .newsletter h2 {
    font-family: 'Grandis_Medium';
    font-size: 2.5vw;
    line-height: 2.6vw;
  }

  .address-card p {
    font-family: 'Roboto-Regular';
    font-size: 1.6vw;
  }

  .header-item span {
    font-size: 3vw;
  }

  .btn-menu span {
    font-size: 3vw;
  }

  .social-networks span {
    font-size: 2vw;
  }

  /* asdad  */
  .lang-switcher {
    display: flex;
    justify-content: space-between;
    font-size: 1.9vw;
  }

  .lectures,
  .privacy-text {
    display: flex;
    font-size: 2.5vw;
    justify-content: space-between;
    margin-top: -15px;
    margin-bottom: 15px;
    width: 75%;
  }

  #navigation {
    flex-grow: 1;
    font-family: 'Grandis_Medium';
    font-size: 8.2vw;
  }

  .lectures,
  .privacy-text {
    display: flex;
    font-size: 3.5vw;
    justify-content: space-between;
    margin-top: -10px;
    margin-bottom: 15px;
    width: 90%;
  }

  #sideOptions .user-btn span {
    font-size: 4vw;
  }

  #sideOptions span {
    font-size: 4vw;
  }

  #menuFooter {
    font-family: 'Grandis_Medium';
    font-size: 1.7vw;
  }

  .address-card-program {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    padding: 0 4vw;
  }

  .address-card-program p {
    font-family: 'Roboto-Regular';
    font-size: 1.5vw;
  }
}

@media (max-width: 768px) {
  #menuContainer {
    justify-content: space-between;
  }

  #menuItems {
    display: flex;
    flex-grow: 1;
    max-height: 60%;
  }

  .login-menu {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    margin-bottom: 10vw;
  }

  .newsletter-input {
    font-size: 2.5vw;
  }

  .newsletter-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Roboto-Regular';
    font-size: 2.5vw;
  }

  .newsletter-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: 'Roboto-Regular';
    font-size: 2.5vw;
  }

  .newsletter-input::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: 'Roboto-Regular';
    font-size: 2.5vw;
  }

  .lectures,
  .privacy-text {
    font-size: 3vw;
  }

  .register-button h2 {
    font-size: 2.7vw;
  }

  .register-button p {
    font-size: 2.1vw;
  }

  .item-button h2 {
    font-size: 4.6vw;
  }

  .item-button.guest_block h2 {
    font-size: 5.5vw;
  }

  .item-button h4 {
    font-size: 3.5vw;
    text-transform: uppercase;
  }

  .item-button p {
    font-size: 3.8vw;
  }

  .social-networks {
    display: none;
  }

  .social-networks-mobile {
    display: block;
    margin-top: 4vw;
  }

  .address-card h2 {
    font-size: 5vw;
  }

  .address-card p {
    font-size: 2.7vw;
  }

  .social-networks-mobile span {
    font-size: 4vw;
    margin-right: 2vw;
  }

  .contact p {
    font-size: 1.2vw;
  }

  #sideOptions span {
    font-size: 6vw;
  }

  .header-item span {
    font-size: 5.5vw;
    font-weight: 500;
  }

  .social-networks-mobile span {
    font-size: 4vw;
    margin-right: 5vw;
  }

  .lectures,
  .privacy-text {
    margin-top: 0;
  }

  .item-container span {
    font-size: 6.2vw;
  }

  .register-button {
    align-items: center;
  }

  .register-button p {
    display: none;
  }

  .register-button h2 {
    font-size: 3.4vw;
    text-align: center;
  }

  .newsletter {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .newsletter h2 {
    font-size: 4vw;
  }

  .loader img {
    width: 40%;
  }

  .newsletter p {
    font-size: 3vw;
  }

  .newsletter-input {
    font-size: 2.5vw;
  }

  .newsletter-input-container span {
    font-size: 1.5em;
    margin-left: 0.1em;
  }

  .lang-switcher {
    display: flex;
    justify-content: space-between;
    font-size: 3vw;
  }

  .login-menu {
    padding: 0;
    line-height: 7vw;
  }

  #menuFooter {
    display: flex;
    font-family: 'Grandis_Medium';
    font-size: 3.5vw;
    justify-content: space-between;
    align-items: flex-end;
  }

  .footer-info {
    margin-top: 5px;
    flex-direction: column;
  }

  #sideOptions .user-btn span {
    font-size: 8.2vw;
  }

  .btn-menu span {
    font-size: 6.5vw;
    font-weight: 700;
  }

  .item-button,
  .search-btn,
  .btn-menu,
  .close-btn {
    cursor: pointer;
    padding: 6vw;
  }

  .header-title,
  .animated-text:after {
    font-family: 'Grandis_Medium';
    font-size: 8.5vw;
    font-weight: 100;
    text-align: left;
    display: inline-block;
    padding: 4vw;
  }

  /* asdad*/
  .quote {
    flex-direction: column;
    justify-content: space-between;
    padding: 4vw;
  }

  .address-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    padding: 0 4vw;
  }

  .newsletter {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4vw;
  }

  .address-card h2 {
    font-size: 7vw;
  }

  .register-button h2 {
    font-size: 3vw;
    text-align: center;
  }

  .header-fix,
  .animated-text:after {
    font-size: 2.5vw;
    padding: 0 4vw;
  }


  .address-card-program h2 {
    display: none;
  }

  .address-card-program h3 {
    font-family: 'Grandis_Medium';
    font-size: 3vw;
    font-weight: 100;
    margin-bottom: 10px;
  }

  .address-card-program p {
    font-family: 'Roboto-Regular';
    font-size: 2.2vw;
  }

  #navigation {
    flex-grow: 1;
    font-family: 'Grandis_Medium';
    font-size: 10.2vw;
  }

  .search-input {
    font-size: 4vw;
  }

  .close-btn {
    font-size: 3vw !important;
  }

  .logo-container {
    max-width: 60%;
  }

  .header-close {
    font-size: 4vw;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */

    font-family: 'Roboto-Regular';
    font-size: 3vw;
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.3);

    font-family: 'Roboto-Regular';
    font-size: 3vw;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.3);

    font-family: 'Roboto-Regular';
    font-size: 3vw;
  }

  .search-container input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 4.5vw;
  }

  .lectures,
  .privacy-text {
    font-size: 3.2vw;
    width: 100%;
  }

  .login-modal {
    font-size: 5vw;
    padding: 4vw;
  }

  .login-modal::before {
    content: '';
  }

  .login-modal span {
    font-size: 4vw;
    padding: 2vw 0 !important;
    width: 40%;
  }

  .login-modal div {
    justify-content: space-around;
    margin-bottom: 3vw;
  }
}





/* Anim */


.item-button,
.hover-button,
.title-container {
  hyphens: auto;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 1200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition: all 1200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition: all 1200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 1200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */

  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
}

.item-button:before,
.item-button:after,
.hover-button:before,
.hover-button:after {
  content: '';
  text-align: center;
  position: absolute;
  top: 110%;
  left: 0%;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  background-color: rgba(235, 236, 139, 0);
  border-right: 0px solid #000;
  -webkit-transition: all 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000), background-color 100ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */

  padding: 2vw;
}

.item-button:hover:before,
.hover-button:hover:before {
  top: 0%;
  height: 100%;
  border-right: 0px solid #000;
}

.item-button:active:before {
  /* background-color:rgb(253, 254, 201); */
}

.item-button:after,
.hover-button:after {
  content: attr(data-title);
  text-transform: uppercase;
  font-family: 'Grandis_Medium';
  font-size: 2.1vw;
  white-space: pre-wrap;
  text-align: left;
  border-right: 0px solid #000;
  background-color: transparent;
  opacity: 1;
  transform: translateY(0px) rotate(0deg);
  -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
}

.item-button:hover:after,
.hover-button:hover:after {
  top: 0%;
  height: 100%;
  border-right: 0px solid #000;
  transform: translateY(0) rotate(0deg);
  opacity: 1;
}

.container-info.hover-button:hover:after {
  top: 12%;
}

.item-button div,
.hover-button h2,
.hover-button p,
.hover-button .button-container div {
  position: relative;
  z-index: 1;
  transform: scale(1);
  -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000), background-color 100ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */

}

.item-button:hover div:nth-child(1),
.hover-button:hover h2,
.hover-button:hover p,
.hover-button:hover .button-container div:nth-child(1) {
  transform: scale(1) translateY(-400px);
  opacity: 0;
}

.item-button div:nth-child(2) p {
  -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
}

.item-button:hover div:nth-child(2) p {
  opacity: 0;
  transform: translateY(-50px);
}




.item-action-button .icon-search-03,
.item-action-button .icon-search-05,
.hover-button .button-container div .icon-search-03 {
  transform: rotate(0);
  -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
}

.item-button:hover .item-action-button .icon-search-03,
.hover-button:hover .button-container div .icon-search-03 {
  transform: rotate(180deg);
}

.item-button:hover .item-action-button .icon-search-05 {
  transform: translateX(15px);
}

.search-btn {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
}

.search-btn:hover {
  /* background-color: #4d67a5; */
}

.search-btn:before,
.btn-menu:before {
  content: '';
  text-align: center;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  background-color: #5976ba;
  border-radius: 50%;
  opacity: 1;
  transform: scale(0);
  -webkit-transition: all 300ms cubic-bezier(0.770, 0.000, 0.175, 1.000), background-color 100ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  ;
  -moz-transition: all 300ms cubic-bezier(0.770, 0.000, 0.175, 1.000), background-color 100ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  ;
  -o-transition: all 300ms cubic-bezier(0.770, 0.000, 0.175, 1.000), background-color 100ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  ;
  transition: all 300ms cubic-bezier(0.770, 0.000, 0.175, 1.000), background-color 100ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
}

.search-btn:hover:before {
  transform: scale(1.5);
}

.search-btn:active:before,
.btn-menu:active:hover {
  background-color: #536fb1;
}

.search-btn .icon-search-02,
.icon-search-05 {
  position: relative;
  z-index: 2;
  color: #000;
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
}

.search-btn:hover .icon-search-02 {
  color: #ebec8a;
  transform: scale(1.6);
}

.icon-search-05:hover {
  color: #ebec8a;
  transform: scale(1.6);
}

.icon-search-05.icon-left:hover,
.icon-search-05.left-arrow:hover,
.icon-search-05.left:hover {
  color: #ebec8a;
  transform: rotate(180deg) scale(1.6) !important;
}

#menuItems span {
  cursor: pointer;
  display: block;
}

#menuItems span,
#menuItems a,
.lang-switcher div,
#sideOptions .icon-search-04,
.social-networks a,
.btn-menu .icon-search-01,
.video-button .icon-play_soma,
.view-mode-box-container span,
.view-sort-button-container span,
.view-sort-dropdown-container div span,
.action-buttons .icon-search-04,
#menuItems p {
  color: #000;
  -webkit-transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all 400ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
}

#menuItems span:hover,
#menuItems a:hover,
.lang-switcher div:hover,
#sideOptions .icon-search-04:hover,
.social-networks a:hover,
.btn-menu:hover .icon-search-01,
.view-mode-box-container span:hover,
.view-sort-button-container:hover span,
.view-sort-dropdown-container div:hover span,
.action-buttons .icon-search-04:hover {
  color: rgba(235, 236, 139, 1) !important;
}

#menuItems p.active_hover {
  color: rgba(235, 236, 139, 1) !important;
}


.lang-switcher div,
#sideOptions .icon-search-04,
.btn-menu .icon-search-01 {
  cursor: pointer;
}

.lang-switcher .active {
  /* color: white !important; */
}

#sideOptions .icon-search-04,
.action-buttons .icon-search-04 {
  transform: scale(1);
  display: block;
}

#sideOptions .icon-search-04:hover,
.social-networks a:hover,
.action-buttons .icon-search-04:hover {
  transform: scale(1.3);
}

.btn-menu:hover .icon-search-01 {
  transform: scaleY(1.5) scaleX(1.2);
}

.video-button:hover .icon-play_soma {
  transform: scale(1.6);
}

/* ARCHIVO*/
#filter-screen .filter-item-tag-container p,
.filter-selector .icon-search-05,
.filter-item-tag .icon-search-05,
.option-data-column .icon-search-03,
.option-data-row .icon-search-03,
.calendar-item-option .icon-search-03 {
  cursor: pointer;
  -webkit-transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
}

.delete-applied-filters {
  cursor: pointer;
}

.delete-applied-filters,
.delete-applied-filters .icon-search-04 {
  display: inline-block;
  -webkit-transition: all 250ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all 250ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all 250ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all 250ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  /* easeOutQuart */
}

.delete-applied-filters .icon-search-04 {
  color: rgb(235, 236, 139);
}

.delete-applied-filters .icon-search-04:hover {
  transform: scale(1.3);
}

.filter-selector .icon-search-05.active,
.filter-item-tag .icon-search-05.active {
  transform: rotate(180px);
}

.option-data-column .icon-search-03,
.option-data-column .icon-04,
.option-data-row .icon-search-03,
.option-data-row .icon-04,
.action-buttons .icon-04,
.calendar-item-option .icon-search-03 {
  display: block;
}

.calendar-item-option .icon-search-03 {
  margin-bottom: 5px;
}

.option-data-column .icon-search-03:hover,
.option-data-row .icon-search-03:hover,
.calendar-item-option .icon-search-03:hover {
  transform: scale(1.1) rotate(90deg);
  color: rgb(235, 236, 139);
}

.option-data-column .icon-04,
.option-data-row .icon-04,
.action-buttons .icon-04 {
  cursor: pointer;
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1);
  /* older webkit */
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
  /* easeOutBack */

  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  /* easeOutBack */
}

.option-data-column .icon-04:hover,
.option-data-row .icon-04:hover,
.action-buttons .icon-04:hover {
  transform: scale(1.2);
  color: rgb(235, 236, 139);
}

@media(max-width:768px) {
  .item-button:after {
    font-size: 2.5vw;
  }
}

.search-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.search-header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.search-header .header-animation,
.header-animation .grid-item,
.header-animation,
.search-animation-container,
.centered-logo,
.social-networks {
  -webkit-transition: all 700ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 700ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 700ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 700ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */

  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
}

.in.animated-text:after {
  transform: translateY(0px) rotate(0deg);
}

.in.animated-text h1 {
  transform: translateY(-50vh) rotate(0deg);
}

.animated-text h1 {
  transform: translateY(0) rotate(0deg);
  -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
}

.animated-text:after {
  content: attr(data-title);
  text-transform: uppercase;
  white-space: pre-wrap;
  text-align: left;
  border-right: 0px solid #000;
  background-color: transparent;
  opacity: 1;
  transform: translateY(50vh) rotate(0deg);
  -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  /* easeInOutQuart */
  position: absolute;
}

.search-content {
  flex: 1;
  overflow: auto;
  position: relative;
}

#navigation a {
  cursor: pointer;
}

.select-input {
  position: relative;
}

.select-input .icon-search-05 {
  display: inline-block;
  position: absolute;
  right: 10px;
  transform: rotate(90deg);
}

.select-input .active {
  display: inline-block;
  position: absolute;
  transform: rotate(270deg);
}

.red-box {
  background: red !important;
}

.pink-box {
  background: pink !important;
}

.guest_block {
  justify-content: center !important;
}